//1.1.41: traffic split
/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

 import '@tensorflow/tfjs-backend-webgl';

 import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
 

 //import * as posenet from '@tensorflow-models/posenet';

 import * as posedetection from '@tensorflow-models/pose-detection';

 import * as cocossd from "@tensorflow-models/coco-ssd";
 

import * as tf from '@tensorflow/tfjs';
 
 import {Camera} from './camera';
 
 import {STATE} from './params';

 import {setBackendAndEnvFlags} from './util';
import { Log, logicalAnd, truncatedNormal } from '@tensorflow/tfjs-core';
import { time } from '@tensorflow/tfjs';
import { getTextureShapeFromLogicalShape } from '@tensorflow/tfjs-backend-webgl/dist/webgl_util';
 
 tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
      tfjsWasm.version_wasm}/dist/`);
 

var synth = window.speechSynthesis;
var utterThis = new SpeechSynthesisUtterance();
var utterThat = new SpeechSynthesisUtterance();
var stats = require("stats-lite");

 let detector, camera, net;


let rafId;
var arryLen; 
var data, startTime, timeLapsed=0, b_timer,ls_log="";


var s_myID="",ad_countDown = new Audio(), ad_chickBitten = new Audio(), ad_shuttle = new Audio()
, ad_lastChick = new Audio(),ad_background= new Audio(),ad_charging = new Audio(),ad_snake=new Audio(),ad_ulti=new Audio()
, ln_configRA=5,sSnakeCount,sLevel, n_outPtTime=0,ln_promptTime=0,b_SI=true,n_startRestPoseTime=-1;

//mode: 0 tutorial, 1 challenge, 2 coachLed;
var n_mode=1,o_animate,o_hrm, n_state=-2,ln_SStdev=1.5, n_stablizingTime=1.5, n_waveTime=0,n_lastWBLRequest=-6
,n_pollingFreq=5,n_initSpawnTime=2, n_initGTime=20,n_initEatTime=18, n_bannerDecoTime=5,s_pc="", utterVoice
,b_waveReadyPrompt=false,n_breakTime=10, ln_utterSpeed = 1.1, b_notSpeaking=true,n_maxLevel=5, s_waitingStatus="", s_waitingRemarks=""
,s_nick="",s_mobile="",s_email="",b_notSending=true,s_recID="",n_diff=0.3, o_practise, a_catchUpInstr=[],b_validReading=false, n_lastExeDate=0
,n_scaleFactor,n_prevMode=-99, b_startState=true,s_comment="", s_prevComment="",n_lastCommentTime,nt_nextComment=-1,n_sDuration=0
,n_holdState=0, s_pComment="", n_practiseIndex=0,arryPractiseIcon=[], arryPractiseText=[], n_pSelectionState=0
,n_pIconHeight=140, b_prevChoice=false
,n_server=1, s_urlPrefix="https://obadmin.achieversprofile.com/",b_showLine=true, s_lastSpoken="", n_lastPoseUpdate=-1;
// 0 local; 1 testing server; 2 production

var s_inAudible = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

///Display and Sound Functions

class ShuttleObj{

  constructor(aWidth, aHeight, atx, aty, aSnakeObj,aImageName, an_BirdHeight, an_vWidth){

    this.width=aWidth;
    this.height=aHeight;

    this.hitCycle=0;
    this.targetX = atx;
    this.targetY = aty;
    this.in_shuttleHitCycles=5;
    
    this.shX = (an_vWidth/2)-aWidth/2; 
    this.shY = an_BirdHeight;

    this.moveX=(atx-(this.shX+aWidth/2))/this.in_shuttleHitCycles;
    this.moveY=(aty-(this.shY+aHeight/2))/this.in_shuttleHitCycles;

    // if condition to decide shuttle here
    this.shuttleName=aImageName;

    this.targetSnake = aSnakeObj;
    this.lastAnimate=0;

  }

  shoot(){

    if(this.hitCycle < this.in_shuttleHitCycles){

      if (timeLapsed - this.lastAnimate > 0.15){

        this.hitCycle++;

        this.shX = this.shX + this.moveX;
        this.shY = this.shY + this.moveY;
  
        
        this.lastAnimate = timeLapsed;
      }

      
      creImage(this.shuttleName,this.shX,this.shY,this.width,this.height);
      return true;
  


    }else{ // hitCycle

      if (this.targetSnake!==null){

        this.targetSnake.visible = false;
        this.targetSnake= {};
  
        //aSnake.setSState(3);

      }

      return false;


    } 

  }

}

class SnakeObj{
  constructor(aWidth, aHeight, aMoveDist,ay){

    this.width=aWidth;
    this.height=aHeight;
    this.moveDist=aMoveDist;
    this.level=0;
    this.side = "R";

    this.sX = 0;    
    this.sY = ay;
    this.state = this.sX==0?0:1;
    this.orientation = "H";
    this.lastAnimate=timeLapsed;
    this.notCatching=0; // when set 3, bird dead line snake to be cleared by shuttle and set null
    this.notTargeted = true;
    this.visible=true; // when set false, shoot by shuttle, to be cleared by bird and set null
    this.imageName="";
    

  }

  setTargetedCurPos(){

    this.notTargeted = false;
    return ({x:this.sX+this.width/2,y:this.sY + this.height/2})

  }

  setSState(aState){

    this.notCatching=aState;
    

  }

  climb(an_gLevel){

    if (timeLapsed - this.lastAnimate >0.2){

      this.state++;
      if(this.state>1){
  
        this.state=0;
  
      }

      
      if (this.orientation==="V" && this.notTargeted){

        this.sY = this.sY - this.moveDist;
        this.level++;
        
      }

      if (this.orientation==="H" ){

        if(this.notCatching<2 && this.notTargeted){

          if(this.side==="L"){

            this.sX = this.sX - this.moveDist;
          }else{
  
            this.sX = this.sX+this.moveDist;
  
          }

          if (this.notCatching===1){
            this.notCatching++;
          }

        }
        
      }

      this.lastAnimate = timeLapsed;
      
    }

  if (!this.notTargeted){
    this.imageName ="snakeH";
  }else{
    this.imageName ="snakeT" + this.state;
  }

  this.imageName = this.imageName + an_gLevel + ".png";

    
    creImage(this.imageName,this.sX,this.sY,this.width,this.height);
    //this.draw();
    
  }

  showSnake(){
    if (this.visible ){

      creImage(this.imageName,this.sX,this.sY,this.width,this.height);

    }
    
  }

  climbIfValid(an_gLevel){

    if(this.notCatching < 3){

      if (this.orientation==="V"){

        if (this.sY-this.moveDist < 0){ //turn to face bird
  
          var tempHeight = this.height;
          this.height = this.width;
          this.width = tempHeight;
          this.sY = this.in_BirdHeight/3;
          if (this.side==="L"){
        
            this.sX = this.in_vWidth-this.width;
  
          }else{
  
            this.sX = -1*this.moveDist;
  
          }
  
          this.orientation="H";
  
      
        }
  
      }
    }else{ //not catching at 3

      return false;

    }

    if(this.visible){

      this.climb(an_gLevel);

      return true;

    }else{
      return false;
    }

  }



  /* replace with creImage
  
    draw(){

    var imgV = document.createElement('img');
    imgV.src = "sy"+this.orientation+".png";
    camera.drawPic(imgV,{x:this.vX,y:this.vY},this.width,this.height);

  }
  
  
  */



  getOrientation(){

    return this.orientation;
  }

  getSide(){
    return this.side;
  }


  getNextXPosition(){

    if (timeLapsed - this.lastAnimate >0.2 && this.notTargeted){

      return this.sX + this.moveDist + this.width;
    
    }else{
      return this.sX+ this.width;
    }
    
  }

/* caught action transfer to child


 caught(LfiP, RfiP){

    var b_inX=false, b_inY=false;

    if(LfiP.y< this.vY+this.height && LfiP.y>this.vY||
      RfiP.y< this.vY+this.height && RfiP.y>this.vY){

        b_inY=true;

    } 

    if(LfiP.x< this.vX+this.width && LfiP.x>this.vX||
      RfiP.x< this.vX+this.width && RfiP.x>this.vX){

        b_inX=true;

    } 

    return b_inY&&b_inX;

  }


*/

}

class BirdObj{

  constructor(aWidth, aHeight,ax,ay){

    this.lastAnimate=0;
    this.width=aWidth;
    this.height=aHeight;
    this.state = generateRandomIntegerInRange(0,1);
    this.bX=ax;
    this.bY=ay;

    //this.caught=false;
    this.visible=true;
    this.ib_bitten=false;
    this.caughtTime = 0;
    this.snakes =[];
    

  }

  getX(){
    return this.bX;
  }

  show(){

    if (this.visible){

      if (timeLapsed - this.lastAnimate >1){

        this.state = generateRandomIntegerInRange(0,3);
        this.lastAnimate=timeLapsed;
        
      }
  
      creImage("BirdT"+this.state+".PNG",this.bX,this.bY,this.width,this.height);


    }

  }

  checkDisplay(an_gLevel){

// animate snakes in lane

    var tempSnakes=[];
    if (this.snakes.length>0){

      for (var s=0; s<this.snakes.length; s++){

        if (this.snakes[s].climbIfValid(an_gLevel)){

          tempSnakes.push(this.snakes[s]);

        }else{
          if(!this.snakes[s].visible){

            this.snakes[s] = null;
          }
          
        }

      }

    }

    this.snakes = tempSnakes;

    if (this.checkBitten()){

      this.bitten();
      return -1;

    }else{

      this.show();
      return 1;
    }


  }

  checkBitten(){

    if (this.snakes.length === 0){
      return false;
    }else{
      if (this.snakes[0].getNextXPosition() > this.getX() + this.width/3){

        this.ib_bitten = true;
        return true;

      }else{
        return false;
      }
    }
    return false;
  }

  showBitten(){

    if( timeLapsed - this.caughtTime <0.5){ 

      creImage("BirdPainT.PNG",this.bX,this.bY,this.width,this.height);

      for(var s=0; s<this.snakes.length; s++){
        
        this.snakes[s].showSnake();

      }


    }else{

      for(var s=0; s<this.snakes.length; s++){
        
        this.snakes[s].setSState(3);

      }
      //this.snakes[0].setSState(3);
      this.snakes=[];
      this.visible = false;


    }

  }
  bitten(){
 
    if (this.caughtTime===0){
      PlaySound(ad_chickBitten,"ChickEaten.mp3",false);
      logIt("Play dead sound");
      creImage("BirdPainT.PNG",this.bX,this.bY,this.width,this.height);
      this.caughtTime = timeLapsed;
      for(var s=0; s<this.snakes.length; s++){
        
        this.snakes[s].setSState(1);

      }
      
    }
    //return true;

  }

}

class LabelValue{
  constructor(aLabel, aValue,aImageFile){

    this.imageFile= aImageFile;
    this.label=aLabel;
    this.rvalue=aValue;
       
  }

}

class HrmObj{
  constructor(an_mode){

    this.in_mode = an_mode;
    this.p_arryRestPoseBL=[];   

    // those below all need to reset

    this.ib_showShuttle=false;
    logIt("init set this.ib_showShuttle:" + this.ib_showShuttle);
    this.ib_shoot=false;

    this.ib_goodSwing=false;
    this.ib_followThru=false;
    this.ib_stepBack=false;

    this.ia_shuttles=[];

    this.ib_Rhanded=true;
    this.n_restPoseStartTime =-1;
    this.in_exEndTime=-1;

    this.ia_exPoseBL=[];
    this.ip_bl=null;

    this.in_prevLkhRk=0;
    this.ib_crossedBody=false;
    this.ib_footForward=false;
    this.in_lowHandTime=-1;
    this.is_exerciseComment = "";
    //o_hrm.gLevel = o_waveInfo.gLevel;
    //o_hrm.nextWaveTime = o_waveInfo.nextWaveTime;
  }

  clearShoot(){
    
    this.ib_showShuttle=false;
    this.is_exerciseComment = "";
    this.ib_shoot=false;

    this.ib_goodSwing=false;
    this.ib_followThru=false;
    this.ib_stepBack=false;

    this.ia_shuttles=[];

    this.ib_Rhanded=true;
    this.n_restPoseStartTime =-1;
    //this.in_exEndTime=-1;

    this.ia_exPoseBL=[];
    this.ip_bl=null;

    this.in_prevLkhRk=0;
    this.ib_crossedBody=false;
    this.ib_footForward=false;
    this.in_lowHandTime=-1;

    logIt("Cleared shoot");
  }

  inExercise(an_timeLapsed, p_l){
    
    this.detectExPose(an_timeLapsed, p_l,false);
    if(this.ib_showShuttle && !this.shoot ){

      this.detectExCompleted(an_timeLapsed, p_l);

    }// detect end Ex


  } 

  detectExCompleted(an_timeLapsed, p_l, aa_exDesc){
    logIt("in detect action completed. this.ib_showShuttle:" +this.ib_showShuttle + " ib_shoot:"+this.ib_shoot+" this.ib_Rhanded:" +this.ib_Rhanded);

    if(this.ib_Rhanded){

      if (p_l[16].x>p_l[12].x && p_l[16].y>p_l[12].y && p_l[16].x>p_l[14].x
        && p_l[0].x>p_l[12].x && getSignedANGXY(p_l[12],p_l[16])>15){
      
        this.ib_crossedBody = true;

      }

        logIt("test conditions - "
        +" p_l[16].x>p_l[12].x:" +(p_l[16].x>p_l[12].x)
        +" p_l[16].y>p_l[12].y:" +(p_l[16].y>p_l[12].y)
        +" p_l[16].x>p_l[14].x:" +(p_l[16].x>p_l[14].x) 
        +" p_l[0].x>p_l[12].x:" +(p_l[0].x>p_l[12].x)
        +" getSignedANGXY(p_l[12],p_l[16]>15):" +(getSignedANGXY(p_l[12],p_l[16])>15)
      
        );

        
      logIt("getSignedANGXY(p_l[12],p_l[16]: "+getSignedANGXY(p_l[12],p_l[16])
      +",p_l[11].x - p_l[12].x:"+(p_l[11].x - p_l[12].x) + " p_l[16].x:"+p_l[16].x+" p_l[12].x:"+p_l[12].x 
      +" p_l[16].y:" + p_l[16].y +" p_l[14].y:" + p_l[14].y+" p_l[12].y:" + p_l[12].y   
        +", b_crossedBody:"+this.ib_crossedBody);

      if (p_l[32].y+5 >p_l[31].y){

        this.ib_footForward = true;

      }

      logIt("right fiY: "+p_l[32].y+", left fiY:"+p_l[31].y+", b_rFootForward:"+this.ib_footForward);

      if(this.ib_crossedBody&&this.ib_footForward){

        this.ib_followThru = true;
        //bLpFollowThru=true;
        
        logIt("follow thru. Ex Ended. BackStep:"+this.ib_stepBack+ " good swing:"+ this.ib_goodSwing+" follow thru:"+this.ib_followThru);
        //bExEnd = true;
        //this.ib_shoot = true;
        //b_notSpeaking = false;
      }

      if ( (p_l[16].y>p_l[14].y && p_l[14].y>p_l[12].y && p_l[15].y>p_l[13].y && p_l[13].y>p_l[11].y)
        ||(p_l[20].y>p_l[24].y)){

        if (this.in_lowHandTime<0){
          logIt("In detectExCompleted, noted end pose");
          this.in_lowHandTime = an_timeLapsed;

        }else if (an_timeLapsed - this.in_lowHandTime > 0.34){

          
          //bExEnd = true;
          this.ib_shoot = true;
          logIt("In detectExCompleted, both hands time out. Ex Ended. ib_shoot:" + this.ib_shoot);

        } 

      }else{
        this.in_lowHandTime=-1;
      }

      logIt(this.ib_shoot+ " Ex End for end pose: p_l[16].y:"+ p_l[16].y +" p_l[14].y:" + p_l[14].y + " p_l[14].y:" +p_l[12].y + " n_lowHandTime:" +this.in_lowHandTime );

      // Good Swing Section

      logIt("getSignedANGXY(p_l[12],p_l[14]): " + getSignedANGXY(p_l[12],p_l[14]) 
      + " getANGby3Points(p_l[12] , p_l[14], p_l[16]):" +getANGby3Points(p_l[12] , p_l[14], p_l[16])
      + " p_l[0].y:" + p_l[0].y + " p_l[16].y"+p_l[16].y+ " p_l[15].y:"+p_l[15].y);
      
        if (p_l[0].x>p_l[12].x 
        //&&  p_l[12].y < ll_prevRsY 
        && p_l[0].y >  p_l[16].y && getSignedANGXY(p_l[12],p_l[14])<50
        &&getANGby3Points(p_l[12] , p_l[14], p_l[16])>130
        && p_l[12].y> p_l[14].y && p_l[14].y> p_l[16].y
        ){// FaceFront check
                
          //bGoodUpSwing = true;
          this.ib_goodSwing = true;
            
          logIt("GS Set good up swing true");
      
        }

        logIt("GS test conditions - "
        +"p_l[0].x>p_l[12].x:" +(p_l[0].x>p_l[12].x)
        +" p_l[0].y > p_l[16].y:" +(p_l[0].y >  p_l[16].y)
        +" getSignedANGXY(p_l[12],p_l[14]):" +(getSignedANGXY(p_l[12],p_l[14])) 
        +" getANGby3Points(p_l[12] , p_l[14], p_l[16]):" +(getANGby3Points(p_l[12] , p_l[14], p_l[16])>130)
        +" p_l[12].y> p_l[14].y:" +(p_l[12].y> p_l[14].y)
        
        +" p_l[14].y> p_l[16].y: " + (p_l[14].y> p_l[16].y)
        
        );


      if (!this.ib_stepBack && !this.ib_goodSwing){ // detect for back step


        var ANGLkhRk = getANGby3Points(p_l[25] , p_l[23],p_l[26]);


        if (getAvgX(this.ia_exPoseBL,28)-p_l[28].x>15 ||getAvgY(this.ia_exPoseBL,28)-p_l[28].y>15 ){

          this.ib_stepBack=true
          //bBkStepMade=true;
          //n_stepBackCount++;
          logIt("BL set bBkStepMade:" +  this.ib_stepBack);
        }

        logIt("p_bl[28].x:" + this.ip_bl[28].x + " p_l[28].x:" + p_l[28].x + " bBkStepMade:" +  this.ib_stepBack);


        this.in_prevLkhRk = ANGLkhRk;

      }//bBkStepMade


    }else{ // left handed

      logIt("is left");
      if (p_l[15].x<p_l[11].x && p_l[15].y>p_l[11].y && p_l[15].x<p_l[13].x
        && p_l[0].x<p_l[11].x && getSignedANGXY(p_l[11],p_l[15])<-15){
      
        this.ib_crossedBody = true;

      }

        logIt("test conditions - "
        +" p_l[15].x<p_l[11].x:" +(p_l[15].x<p_l[11].x)
        +" p_l[15].y>p_l[11].y:" +(p_l[15].y>p_l[11].y)
        +" p_l[15].x<p_l[13].x:" +(p_l[15].x<p_l[13].x) 
        +" p_l[0].x<p_l[11].x:" +(p_l[0].x<p_l[11].x)
        +" getSignedANGXY(p_l[11],p_l[15]>15):" +(getSignedANGXY(p_l[11],p_l[15])<-15)
                
        );

        
      logIt("getSignedANGXY(p_l[11],p_l[15]: "+getSignedANGXY(p_l[11],p_l[15]) 
      +",p_l[11].x - p_l[12].x:"+(p_l[11].x - p_l[12].x) + " p_l[15].x:"+p_l[15].x+" p_l[11].x:"+p_l[11].x 
      +" p_l[15].y:" + p_l[15].y +" p_l[13].y:" + p_l[13].y+" p_l[12].y:" + p_l[11].y   
        +", b_crossedBody:"+this.ib_crossedBody);

      if (p_l[32].y < p_l[31].y+5){

        this.ib_footForward = true;

      }

      logIt("right fiY: "+p_l[32].y+", left fiY:"+p_l[31].y+", b_rFootForward:"+this.ib_footForward);

      if(this.ib_crossedBody&&this.ib_footForward){

        this.ib_followThru = true;
        
        logIt("follow thru. Ex Ended. BackStep:"+this.ib_stepBack+ " good swing:"+ this.ib_goodSwing+" follow thru:"+this.ib_followThru);
        
        //this.ib_shoot = true;
        //b_notSpeaking = false;
      }

      if ( (p_l[16].y>p_l[14].y && p_l[14].y>p_l[12].y && p_l[15].y>p_l[13].y && p_l[13].y>p_l[11].y)
      ||(p_l[19].y>p_l[23].y)){

        if (this.in_lowHandTime===0){
          this.in_lowHandTime = an_timeLapsed;

        }else if (an_timeLapsed - this.in_lowHandTime > 0.4){

          //bExEnd = true;
          
          this.ib_shoot = true;
        } 

      }else{

        this.in_lowHandTime =-1;

      }

     // Good Swing Section

      logIt("getSignedANGXY(p_l[11],p_l[13]): " + getSignedANGXY(p_l[11],p_l[13]) 
      + " getANGby3Points(p_l[11] , p_l[13], p_l[15]):" +getANGby3Points(p_l[11] , p_l[13], p_l[15])
      + " p_l[0].y:" + p_l[0].y + " p_l[15].y"+p_l[15].y);
      
        if (p_l[0].x<p_l[11].x 
        //&&  p_l[12].y < ll_prevRsY 
        && p_l[0].y >  p_l[15].y && getSignedANGXY(p_l[11],p_l[13])>-50
        &&getANGby3Points(p_l[11] , p_l[13], p_l[15])>130
        && p_l[11].y> p_l[13].y && p_l[13].y> p_l[15].y
        ){// FaceFront check
                
          //bGoodUpSwing = true;
          this.ib_goodSwing = true;
            
          logIt("GS Set good up swing true");
      
        }

        logIt("GS test conditions - "
        +"p_l[0].x>p_l[12].x:" +(p_l[0].x<p_l[11].x)
        +" p_l[0].y > p_l[16].y:" +(p_l[0].y >  p_l[15].y)
        +" getSignedANGXY(p_l[12],p_l[14]):" +(getSignedANGXY(p_l[11],p_l[13])) 
        +" getANGby3Points(p_l[12] , p_l[14], p_l[16]):" +(getANGby3Points(p_l[11] , p_l[13], p_l[15])>130)
        +" p_l[12].y> p_l[14].y:" +(p_l[11].y> p_l[13].y)          
        +" p_l[14].y> p_l[16].y: " + (p_l[13].y> p_l[15].y)
        
        );


      if (!this.ib_stepBack && !this.ib_goodSwing){ // detect for back step


        var ANGLkhRk = getANGby3Points(p_l[25] , p_l[23],p_l[26]);

        if (getAvgX(this.ia_exPoseBL,27)- this.ip_bl[27].x >15 || getAvgY(this.ia_exPoseBL,27)- this.ip_bl[27].y >15 ){

          this.ib_stepBack=true;
          //bBkStepMade=true;
          //n_stepBackCount++;
          logIt("BL set bBkStepMade:" + this.ib_stepBack);
        }

        logIt("p_bl[27].x:" + this.ip_bl[27].x + " p_l[27].x:" + p_l[27].x + " bBkStepMade:" + this.ib_stepBack);


        this.in_prevLkhRk = ANGLkhRk;

      }//bBkStepMade

    
    }

    if (this.ib_shoot){

      logIt("clearing ia_shuttles");
      this.ia_shuttles=[];

      if (this.ib_stepBack){
        logIt("push ShuttleSB.png");
        this.ia_shuttles.push("ShuttleSB.png");
      }
        
      
      if (this.ib_followThru){
        logIt("push ShuttleFT.png");
        this.ia_shuttles.push("ShuttleFT.png");
      }
        

      if (this.ib_goodSwing){
        logIt("push ShuttleS.png");
        this.ia_shuttles.push("ShuttleS.png");
      }
        

      this.ib_showShuttle = false;
      logIt("set exEndTime");
      this.in_exEndTime=an_timeLapsed;

    }

  }

  detectExPose(an_timeLapsed, p_l,ab_practise){

    
    if (!this.ib_showShuttle && !this.ib_shoot)
      this.ia_exPoseBL.push(p_l);

    //logIt("this.ia_exPoseBL:" + this.ia_exPoseBL.length);  

   // logIt("inExercise, showShuttle:" + this.ib_showShuttle + " shoot:" + this.ib_shoot);
    if (this.ia_exPoseBL.length> arryLen-1 ){
      this.ia_exPoseBL = this.ia_exPoseBL.slice(1,arryLen);
    }
      //logIt("array size:"+this.ia_exPoseBL.length+" checkStdev(this.this.ia_exPoseBL,27,ln_SStdev):" +(checkStdev(this.ia_exPoseBL,27,ln_SStdev)) + " checkStdev(this.ia_exPoseBL,24,ln_SStdev):" +(checkStdev(this.ia_exPoseBL,24,ln_SStdev))
      //+" checkStdev(this.ia_exPoseBL,15,ln_SStdev):" +(checkStdev(this.ia_exPoseBL,15,ln_SStdev)) +" checkStdev(this.ia_exPoseBL,14,ln_SStdev):" +(checkStdev(this.ia_exPoseBL,14,ln_SStdev)));

    if (this.ia_exPoseBL.length=== arryLen-1){

      if (!this.ib_showShuttle && !this.ib_shoot&& an_timeLapsed-this.in_exEndTime>1.5 
        && checkStdev(this.ia_exPoseBL,27,ln_SStdev) &&checkStdev(this.ia_exPoseBL,24,ln_SStdev) 
        &&checkStdev(this.ia_exPoseBL,15,ln_SStdev) &&checkStdev(this.ia_exPoseBL,14,ln_SStdev)
        ){
        // check for ex start pose

        logIt("in check ex start pose");
        var ls_comment="";

        var ln_curLkhRk =  getANGby3Points(p_l[25],p_l[23],p_l[26]);
        //logIt("ln_curLkhRk:" + ln_curLkhRk);

      
        logIt("getAvgY(this.ia_exPoseBL,15):" +getAvgY(this.ia_exPoseBL,15)+ " getAvgY(this.ia_exPoseBL,11):" +getAvgY(this.ia_exPoseBL,11) 
        +" getAvgY(this.ia_exPoseBL,16):"+getAvgY(this.ia_exPoseBL,16)+" getAvgY(this.ia_exPoseBL,12):" +getAvgY(this.ia_exPoseBL,12));
       // if (stats.mean(arryLwY)>stats.mean(arryLsY) || stats.mean(arryRwY)>stats.mean(arryRsY)){
        if (getAvgY(this.ia_exPoseBL,15)> getAvgY(this.ia_exPoseBL,11) 
        || getAvgY(this.ia_exPoseBL,16)> getAvgY(this.ia_exPoseBL,12) ){
 
          if(getAvgY(this.ia_exPoseBL,15)> getAvgY(this.ia_exPoseBL,11) 
          && getAvgY(this.ia_exPoseBL,16)> getAvgY(this.ia_exPoseBL,12)){

            ls_comment = "Both hands higher.";

          }else if (getAvgY(this.ia_exPoseBL,16)> getAvgY(this.ia_exPoseBL,12)){
            ls_comment = "Right hand higher.";
          }else{
            ls_comment = "Left hand higher.";
          }
          
          logIt("stats.mean(arryLwY):" + getAvgY(this.ia_exPoseBL,15)
          + " stats.mean(arryLsY) : " + getAvgY(this.ia_exPoseBL,11)
          + " stats.mean(arryRwY):" +getAvgY(this.ia_exPoseBL,16)
          + " stats.mean(arryRsY):" + getAvgY(this.ia_exPoseBL,12));
  
        }else if(getAvgY(this.ia_exPoseBL,15)> getAvgY(this.ia_exPoseBL,16)){
  
          this.ib_Rhanded = false;
          logIt("set as left handed");
  
        }

        logIt("getAvgX(this.ia_exPoseBL,30):"+getAvgX(this.ia_exPoseBL,30)
        +" getAvgX(this.ia_exPoseBL,29):" +getAvgX(this.ia_exPoseBL,29));
        //if(stats.mean(arryRheX) > stats.mean(arryLheX)){
        if(getAvgX(this.ia_exPoseBL,30)>getAvgX(this.ia_exPoseBL,29)){

          ls_comment = "Show racket feet.";
          logIt("stats.mean(arryLheX)< stats.mean(arryRheX)");
    
    
        //}else if (p.keypoints[11].y<p.keypoints[15].y ){
        }

        /*

        else if (getAvgY(this.ia_exPoseBL,11)<getAvgY(this.ia_exPoseBL,15)){
  
          ls_comment = "Wrist above shoulder.";
          logIt("getAvgY(this.ia_exPoseBL,11): "+getAvgY(this.ia_exPoseBL,11)+" getAvgY(this.ia_exPoseBL,15):" +getAvgY(this.ia_exPoseBL,15) );
  
        }


        */




        logIt("before comment: " + ls_comment.length);

        if (ab_practise){
  
            if ( ls_comment.length ===0){
      
              this.ib_showShuttle = true;
   
              logIt("got ex pose set this.ib_showShuttle:" + this.ib_showShuttle);
              this.in_prevLkhRk=ln_curLkhRk;
             
              this.ip_bl= p_l;
  
              //PlaySound(ad_shuttle,"ShuttleReady.mp3",false);
              logIt("Shuttled. Good start loop.  ln_curLkhRk:" + ln_curLkhRk);
        
            }else{
        
              logIt("need correction: ");
              //instructRep("Into exercise pose "+ls_comment, 1.5*ln_configRA);

              logIt("setComment for:  exercise pose "+ls_comment);

              setComment(timeLapsed, "Exercise pose "+ls_comment);
        
        
            }
    
        
        }else{ 
          // actual game
          if(o_animate.ia_arrySnake.length >0 ){
  
            if ( ls_comment.length ===0){
      
              this.ib_showShuttle = true;
   
              logIt("got ex pose set this.ib_showShuttle:" + this.ib_showShuttle);
              this.in_prevLkhRk=ln_curLkhRk;
             
              this.ip_bl= p_l;
  
              PlaySound(ad_shuttle,"ShuttleReady.mp3",false);
              logIt("Shuttled. Good start loop.  ln_curLkhRk:" + ln_curLkhRk);
        
            }else{
        
              logIt("need correction: ");
              //instructRep(ls_comment,ln_configRA);

              logIt("setComment for: "+ ls_comment);

              setComment(timeLapsed, ls_comment);
              
        
        
            }
    
          }

        }
 

      // detecting Ex pose
      }
    }   
     
  } 
  detectRestPose(an_timeLapsed, ap_l){

    var b_stable=false, b_correct=false;
    this.p_arryRestPoseBL.push(ap_l);

    logIt("an_timeLapsed:" + an_timeLapsed+ " n_restPoseStartTime:" + this.n_restPoseStartTime 
    + " n_stablizingTime:" + n_stablizingTime);

    if (this.n_restPoseStartTime ===-1){

      this.n_restPoseStartTime =an_timeLapsed;
      
      return -1;
    }else if( an_timeLapsed - this.n_restPoseStartTime > n_stablizingTime){

      logIt("this.p_arryRestPoseBL.length:" + this.p_arryRestPoseBL.length);
      if ( this.p_arryRestPoseBL.length>5){
        this.p_arryRestPoseBL = this.p_arryRestPoseBL.slice(1,this.p_arryRestPoseBL.length);

        /*

        logIt("checkStdev(this.p_arryRestPoseBL,27,ln_SStdev):" +checkStdev(this.p_arryRestPoseBL,27,ln_SStdev) + " checkStdev(this.p_arryRestPoseBL,28,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,28,ln_SStdev)
        +"checkStdev(this.p_arryRestPoseBL,23,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,23,ln_SStdev)+ "checkStdev(this.p_arryRestPoseBL,24,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,24,ln_SStdev)
        +"checkStdev(this.p_arryRestPoseBL,15,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,15,ln_SStdev)+"checkStdev(this.p_arryRestPoseBL,16,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,16,ln_SStdev)
        +"checkStdev(this.p_arryRestPoseBL,13,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,13,ln_SStdev)+"checkStdev(this.p_arryRestPoseBL,14,ln_SStdev):" +checkStdev(this.p_arryRestPoseBL,14,ln_SStdev)
        +"checkStdev(this.p_arryRestPoseBL,11,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,11,ln_SStdev)+"checkStdev(this.p_arryRestPoseBL,12,ln_SStdev):" +checkStdev(this.p_arryRestPoseBL,12,ln_SStdev)
        +"checkStdev(this.p_arryRestPoseBL,0,ln_SStdev):"+checkStdev(this.p_arryRestPoseBL,0,ln_SStdev)
        
        );

        */


        b_stable = checkStdev(this.p_arryRestPoseBL,27,ln_SStdev) &&checkStdev(this.p_arryRestPoseBL,28,ln_SStdev)
        &&checkStdev(this.p_arryRestPoseBL,23,ln_SStdev)&&checkStdev(this.p_arryRestPoseBL,24,ln_SStdev)
        &&checkStdev(this.p_arryRestPoseBL,15,ln_SStdev)&&checkStdev(this.p_arryRestPoseBL,16,ln_SStdev)
        &&checkStdev(this.p_arryRestPoseBL,13,ln_SStdev)&&checkStdev(this.p_arryRestPoseBL,14,ln_SStdev)
        &&checkStdev(this.p_arryRestPoseBL,11,ln_SStdev)&&checkStdev(this.p_arryRestPoseBL,12,ln_SStdev) &&checkStdev(this.p_arryRestPoseBL,0,ln_SStdev);

        b_correct = getAvgY(this.p_arryRestPoseBL,27)>getAvgY(this.p_arryRestPoseBL,25) &&getAvgY(this.p_arryRestPoseBL,28)>getAvgY(this.p_arryRestPoseBL,26)
        &&getAvgY(this.p_arryRestPoseBL,25)>getAvgY(this.p_arryRestPoseBL,23) &&getAvgY(this.p_arryRestPoseBL,26)>getAvgY(this.p_arryRestPoseBL,24) 
        &&getAvgY(this.p_arryRestPoseBL,15)>getAvgY(this.p_arryRestPoseBL,13) &&getAvgY(this.p_arryRestPoseBL,16)>getAvgY(this.p_arryRestPoseBL,14)
        &&getAvgY(this.p_arryRestPoseBL,13)>getAvgY(this.p_arryRestPoseBL,11) &&getAvgY(this.p_arryRestPoseBL,14)>getAvgY(this.p_arryRestPoseBL,12) 

        &&getAvgX(this.p_arryRestPoseBL,15)>getAvgX(this.p_arryRestPoseBL,23) &&getAvgX(this.p_arryRestPoseBL,24)>getAvgX(this.p_arryRestPoseBL,16) ;

        logIt("is stable: " + b_stable + " is correct:" + b_correct);

        //b_stable=true;
        //b_correct=true;

        if (b_stable&&b_correct){

          logIt("detectRestPose return 1");
          return 1;
        }else{

          // return -2 for stability and -3 for wrong pose

          if (!(b_stable)){

            logIt("detectRestPose return -2");
            return -2;

          }else{

            logIt("detectRestPose return -3");
            return -3;
          }

        }


      }else{

        logIt("detectRestPose array less than 5 return -5");
        return -1;
      }

    }else{
      logIt("detectRestPose return -1");
      return -1;
    }

  }





}

class AnimateObj{
  constructor(an_diff){
// When call contructor all reset to init values. Call specific method to set Time to Eat or Spawn Time 


    this.in_vWidth = 0;
    this.in_vHeight = 0;
    this.in_scaleFactor = 0;
    this.ib_isMobile = false;

    this.in_birdHeight =0;     
    this.in_birdWidth =0;
    this.in_birdSpacing=1.5;
   
    this.in_birdCount =5;
    this.in_birdsKilled = 0;
    this.ia_arryBird = [];
    
    this.in_SnakeHeight = 0;
    this.in_SnakeWidth = 0;
    this.in_sLaneCounter=0;

    this.in_ShuttleWidth = 0;
    this.in_ShuttleHeight = 0;

    this.in_imgWWidth = 0.6 * this.in_vWidth;
    this.in_imgWHeight = 402*this.in_imgWWidth/544;

    this.newWaveTime = new Date();
    this.newWaveTime.setHours(this.newWaveTime.getHours()-1);

    this.in_nextEventTime = 0;

    this.in_diff = an_diff;

    this.in_wavesCleared=0;
    this.in_gLevel = 0;
    this.in_gLevelTime=0;
    //this.in_timeToEat = 0;
    
    this.in_spawnTime = 0;
    this.in_timeToSpawn = 0;
    this.in_waveStartTime = 0;
    this.in_moveDist=0;
    this.in_birdsKilled=0;
    
    this.in_lastPlayDD =0;
    this.in_lastPlayLC =0;
    this.ib_inWave = true;
    this.ib_waveAlive = true;

    this.in_goodSeq=0;   
    this.in_goodSeqTarget=4;
    this.in_glowTime=0;
    this.in_chargeBarHeight=0;
    this.in_chargeBarWidth=0;
    this.in_1percentWidth=0;

    this.ib_cbGlow=false;

    this.in_killSnake=0;
    this.ia_arrySnake=[];
    this.ia_arryShuttle=[];

    this.in_snakeCount=0;
    this.in_shuttleCount=0;
    this.in_superCharge=0;
    this.in_perfectCount=0;
    this.in_goodSwingCount=0;
    this.in_followThruCount=0;
    this.in_stepBackCount=0;

    // reporting stats
    this.in_snakeCountAll=0;
    this.in_shuttleCountAll=0;
    this.in_superChargeAll=0;
    this.in_perfectCountAll=0;
    this.in_goodSwingCountAll=0;
    this.in_followThruCountAll=0;
    this.in_stepBackCountAll=0;
    this.in_gameTimeAll=0;

  }

  saveWave(ab_terminated, an_timeLapsed){

    if(ab_terminated){

      this.in_gameTimeAll= this.in_gameTimeAll+(an_timeLapsed - this.in_waveStartTime);

    }else{

      this.in_gameTimeAll= this.in_gameTimeAll + this.in_gLevelTime;
      this.in_wavesCleared++;

    }
    
    this.in_snakeCountAll=this.in_snakeCountAll+this.in_snakeCount;
    this.in_shuttleCountAll=this.in_shuttleCountAll+this.in_shuttleCount;
    this.in_superChargeAll=this.in_superChargeAll+this.in_superCharge;
    this.in_perfectCountAll=this.in_perfectCountAll+this.in_perfectCount;
    this.in_goodSwingCountAll=this.in_goodSwingCountAll+this.in_goodSwingCount;
    this.in_followThruCountAll=this.in_followThruCountAll+this.in_followThruCount;
    this.in_stepBackCountAll=this.in_stepBackCountAll+this.in_stepBackCount;

    this.in_snakeCount=0;
    this.in_shuttleCount=0;
    this.in_superCharge=0;
    this.in_perfectCount=0;
    this.in_goodSwingCount=0;
    this.in_followThruCount=0;
    this.in_stepBackCount=0;


  }

  initiateShuttle(aSnake,aImageName){

    var p_target = aSnake.setTargetedCurPos();
    this.ia_arryShuttle.push(new ShuttleObj(this.in_ShuttleWidth,this.in_ShuttleHeight,p_target.x,p_target.y, aSnake,aImageName,this.in_birdHeight,this.in_vWidth));
  
  }

  targetAllSnakes(){

    for(var s=0; s< this.ia_arrySnake.length; s++ ){

      this.ia_arrySnake[s].notTargeted = false;
      this.ia_arrySnake[s].setSState(2);

    }

  }

  waveCleared(){

    for (var j=0; j< this.ia_arryBird.length;j++){
               
      if (this.ia_arryBird[j].visible){
  
        logIt("set snakes[] for Bird: " + j);
        this.ia_arryBird[j].snakes=[];
  
      }
         
    }// end bird loop

    if (this.ia_arrySnake.length > 0){

      for (var s=0; s< this.ia_arrySnake.length;s++){
        this.ia_arrySnake[s] =null;
      }

      this.ia_arrySnake=[];

    }

    this.ia_arryShuttle=[];

  }

  animateGame(an_timeLapsed){

    if(an_timeLapsed<this.in_waveStartTime+this.in_gLevelTime){
    // within Game time

    // sound section
      if (this.in_waveStartTime+this.in_gLevelTime - an_timeLapsed < 10){

        if ( an_timeLapsed- this.in_lastPlayDD > 2){
          logIt("Playing counting");
          PlaySound(ad_countDown, "DingDong.mp3",false);
          this.in_lastPlayDD = an_timeLapsed;
         
        }

      }else if(this.in_birdCount - this.in_birdsKilled===1){

        if (an_timeLapsed-this.in_lastPlayLC>1){

          logIt("Playing lastChick" );
          PlaySound(ad_lastChick,"LastChick.mp3",false);
          this.in_lastPlayLC = an_timeLapsed;

        }

      }
      // end sound section

      logIt("showing shuttle: " + o_hrm.ib_showShuttle + " shooting:" + o_hrm.ib_shoot);
      if(o_hrm.ib_showShuttle){

        logIt("showing shuttle image.");   
        
        creImage("shuttle.png",( this.in_vWidth/2)- this.in_ShuttleWidth/2
        ,this.in_birdHeight, this.in_ShuttleWidth, this.in_ShuttleHeight);

      }else if(o_hrm.ib_shoot){

        PlaySound(ad_shuttle,"HitShuttle.mp3",false);
        this.in_shuttleCount++;
        this.shooting(o_hrm.ia_shuttles);
        o_hrm.clearShoot();

      }

      return this.showElements(an_timeLapsed);


    }else{//return as wave ended by time

      if (n_mode===2){

        s_waitingRemarks = "Wave Completed";
        s_waitingStatus = "Play Ended";
        if (parseInt(s_myID)%2===0){
          sendRealTimeUpdates(s_myID,Math.round(this.in_gLevelTime)
          ,this.in_superCharge,this.in_perfectCount,this.in_snakeCount,this.in_gLevel
          ,this.in_shuttleCount,this.in_goodSwingCount,this.in_followThruCount
          ,this.in_stepBackCount,s_waitingStatus,s_waitingRemarks);
        }
 
      }
      return 4;
    }

  }

  showElements(an_timeLapsed){
    
    logIt("arryShuttle.length:"+this.ia_arryShuttle.length)

    if (this.in_goodSeq>0){
      this.chargingBar(an_timeLapsed);
    }

    if (this.ia_arryShuttle.length>0){
  
      var arryTempShuttle=[];
  
      for (var j=0; j< this.ia_arryShuttle.length;j++){
  
        logIt("Shooting for shuttle:" + j);
        if(this.ia_arryShuttle[j].shoot()){
  
          arryTempShuttle.push(this.ia_arryShuttle[j]);
        }
  
      }
  
      
      this.ia_arryShuttle = arryTempShuttle;
  
      logIt("check arryShuttle.length:" + this.ia_arryShuttle.length);
      if (this.ia_arryShuttle.length===0){
  
        var tempSnake = [];
  
        logIt("Looping for snakes");
        for (var s=0; s< this.ia_arrySnake.length;s++){
  
          if (this.ia_arrySnake[s].visible){
            logIt("Keeping snake:" + s);
            tempSnake.push(this.ia_arrySnake[s]);
          }
  
        }
  
        this.ia_arrySnake = tempSnake;
        sSnakeCount.style.display="block";
        var n_snakes=this.in_snakeCount+ this.in_snakeCountAll+0;
        sSnakeCount.innerHTML = "Snake Count:" +n_snakes;

      }
  
    }


    for (var j=0; j< this.ia_arryBird.length;j++){
  
      var b_caught=false;
  
      //logIt("Looping for bird: " + j + " bitten: " + this.ia_arryBird[j].ib_bitten);
  
      if (!this.ia_arryBird[j].ib_bitten){

        if(this.ia_arryBird[j].checkDisplay(this.in_gLevel)===-1){
          logIt("Killed bird: " + j);
          this.in_birdsKilled++;
        }
  
      }else if(this.ia_arryBird[j].visible){
        
        this.ia_arryBird[j].showBitten();

      }
         
    }// end bird loop
  
    //logIt("Birds Eaten:" +  this.in_birdsKilled);

    if (this.in_birdsKilled===5){

      if (n_mode===2){

        s_waitingRemarks = "Wave Failed";
        s_waitingStatus = "Play Ended";

        sendRealTimeUpdates(s_myID,Math.round(an_timeLapsed-this.in_waveStartTime)
        ,this.in_superCharge,this.in_perfectCount,this.in_snakeCount,this.in_gLevel
        ,this.in_shuttleCount,this.in_goodSwingCount,
        this.in_followThruCount,this.in_stepBackCount,"Play Ended","Wave Failed");
      }

      return -8;
        
    }
  
    if (an_timeLapsed > this.in_spawnTime){
  
      var b_searchLane = true, loopCount=0;
  
      while(b_searchLane ){
  
        if (this.in_sLaneCounter>4){
          this.in_sLaneCounter = 0;
        }
  
        if (this.ia_arryBird[this.in_sLaneCounter].visible){
  
          var o_newSnake = new SnakeObj(this.in_SnakeWidth,this.in_SnakeHeight,this.in_MoveDist, 2*this.in_birdHeight 
            + (this.in_birdSpacing*this.in_birdHeight)*this.in_sLaneCounter + this.in_birdHeight/3);

          this.ia_arryBird[this.in_sLaneCounter].snakes.push(o_newSnake);  

          this.ia_arrySnake.push(o_newSnake);

          b_searchLane = false;
        }
  
        this.in_sLaneCounter++;
        loopCount++;
  
        if (loopCount>4){
  
          b_searchLane = false;
  
        }
  
      }
      this.in_spawnTime = an_timeLapsed + this.in_timeToSpawn;
    }

    return 2;
  }

  chargingBar(an_timeLapsed){
  
    //var n_cbWidth=n_goodSeq * 20 *n_1percentWidth;
    var xPos=this.in_vWidth*0.1;
    var s_cbName="";
    if (this.in_goodSeq<4)
    {
      
      s_cbName="cb"+this.in_goodSeq+".png";

    }else if(this.in_goodSeq===4){
      
      //xPos = ln_vWidth/2 - (40 *n_1percentWidth);

      if(an_timeLapsed - this.in_glowTime>0.4){

        this.in_glowTime = an_timeLapsed;
        this.ib_cbGlow=!this.ib_cbGlow;

      }

      if (this.ib_cbGlow){

        s_cbName ="cb41.png";


      }else{

        s_cbName ="cb40.png";

      }

    }
    
    if (s_cbName.length>0){

      creImage(s_cbName,xPos
        , 0,this.in_chargeBarWidth,this.in_chargeBarHeight );

    }

    
  }

  shooting(aa_shuttles){

    if (aa_shuttles.length>0){ // at least 1 movement correct
     
      if (aa_shuttles.indexOf("ShuttleS.png") > -1){

        this.in_goodSwingCount++;

      }

      if (aa_shuttles.indexOf("ShuttleFT.png") > -1){

        this.in_followThruCount++;

      }
      if (aa_shuttles.indexOf("ShuttleSB.png") > -1){

        this.in_stepBackCount++;

      }

      this.in_killSnake=aa_shuttles.length;
      if (aa_shuttles.length===3){
        this.in_perfectCount++;
        this.in_goodSeq++;
        this.praisePlayer();

        //logIt("in shooting check kills: " + this.in_killSnake + " goodSeq:" +this.in_goodSeq 
        //+ " aa_shuttles.length: " + aa_shuttles.length);

        if (this.in_goodSeq>this.in_goodSeqTarget){

          this.in_goodSeq=0;
          this.in_killSnake=4;
          this.in_superCharge++;
          //logIt("play UnltiSound");
          PlaySound(ad_ulti,"UltiHit.mp3",false);
  
        }else{
          
          //logIt("Playing Charging Sound");
          PlaySound(ad_charging,"ChargingBar.mp3",false);
          
        }

      }else{
        this.in_goodSeq=0;
      }

      var tempSnake=[], n_foundTargets=0;
      for (var k=0; k<this.ia_arrySnake.length; k++){
  
        if(this.ia_arrySnake[k].notCatching===3){ // non-display snakes killed in previously.
          this.ia_arrySnake[k] = null;
        }else{
          tempSnake.push(this.ia_arrySnake[k]); //grab first snakes to be targeted
  
          if (this.in_killSnake===4){
  
            logIt("Init Gshuttle for snake: " + k);
            this.initiateShuttle(this.ia_arrySnake[k],"Gshuttle.png");
            this.in_snakeCount++;
            
  
          }else{
  
           if (n_foundTargets < this.in_killSnake){

              this.initiateShuttle(this.ia_arrySnake[k],aa_shuttles[n_foundTargets]);
              //logIt("Init shuttle for snake: " + k + " n_foundTargets:" +n_foundTargets);
              this.in_snakeCount++;
              n_foundTargets++;
            }
  
          }
  
        }
      }

      if(n_mode===2){// coach led

        sendRealTimeUpdates(s_myID,Math.round(timeLapsed-this.in_waveStartTime)
        ,this.in_superCharge,this.in_perfectCount,this.in_snakeCount,this.in_gLevel,this.in_shuttleCount
        ,this.in_goodSwingCount,this.in_followThruCount,this.in_stepBackCount,"In Play","");

      }

      if(this.in_killSnake<4 && n_foundTargets < this.in_killSnake){

        for (var r=n_foundTargets; r<this.in_killSnake; r++ ){

          var p_target;

          if(r===1){

            var p_target = {x:this.in_vWidth,y:this.in_vHeight-1.5*this.in_ShuttleHeight};
          
          }
          
          if(r===2){

            var p_target = {x:0,y:this.in_vHeight-1.5*this.in_ShuttleHeight};
           
          }

          this.ia_arryShuttle.push(new ShuttleObj(this.in_ShuttleWidth,this.in_ShuttleHeight,p_target.x,p_target.y, null,aa_shuttles[r],this.in_birdHeight,this.in_vWidth));

        }

      }

      this.ia_arrySnake=tempSnake;

      //continue with arryShuttle loop

    }

    this.in_killSnake=0;

  }

  praisePlayer(){

    var s_praise="";
    if(this.in_goodSeq===1){
      s_praise="Good!";

    }else if(this.in_goodSeq===2){
      s_praise="Great!";
    }else if(this.in_goodSeq===3){
      s_praise="Excellent!";
    }else if(this.in_goodSeq===4){
      s_praise="Perfect!";
    }else if(this.in_goodSeq===5){
      s_praise="Champion!";
    }

    //instructNow(s_praise);
    logIt("setComment for: "+ s_praise);

    setComment(timeLapsed, s_praise);

  }

  showWaveBanner(){
    creImage("w"+this.in_gLevel+".png",(this.in_vWidth-this.in_imgWWidth)/2
    ,(this.in_vHeight-this.in_imgWHeight)/2,this.in_imgWWidth,this.in_imgWHeight);
  }

  initBirdParam(){
    
    this.in_birdsKilled=0;
    this.ia_arryBird=[];
    for (var i = 0; i < this.in_birdCount; i++) { 
  
      logIt("init bird with n_BirdWidth:" +this.in_birdCount+ ", n_BirdHeight:" + this.in_birdHeight 
      +", x:" + (this.in_vWidth-this.in_birdWidth)
      + " y:" + (2*this.in_birdHeight + (this.in_birdSpacing*this.in_birdHeight)*i) );

      this.ia_arryBird.push(new BirdObj(this.in_birdWidth,this.in_birdHeight, this.in_vWidth-this.in_birdWidth
        , 2*this.in_birdHeight + (this.in_birdSpacing*this.in_birdHeight)*i ));  

    }
    this.ia_arrySnake=[];
    this.ia_arryShuttle=[];

  }

  initGameParam(an_initTimeToEat, an_initSpawnTime, an_gLevelInitTime){

    //this.in_glevel = an_gLevel;

    this.in_lastPlayDD =0;
    this.in_MoveDist 
    = (this.in_vWidth - (this.in_birdWidth/2)-this.in_SnakeWidth)/(an_initTimeToEat/0.2);

    this.in_gLevelTime = an_gLevelInitTime * Math.pow(1+this.in_diff,this.in_gLevel-1);
    this.in_MoveDist = this.in_MoveDist* Math.pow(1+this.in_diff,this.in_gLevel-1);
    this.in_timeToSpawn = an_initSpawnTime* Math.pow(1-this.in_diff,this.in_gLevel-1);


  }

  initDeviceParam(){

    //return {n_videoWidth:ln_vWidth,n_videoHeight:ln_vHeight,n_scaleFactor: n_scaleFactor, b_isMobile: b_isMobile }
    var o_videoParm = setUpDeviceParam();
    this.in_vWidth = o_videoParm.n_videoWidth;
    this.in_vHeight = o_videoParm.n_videoHeight;
    this.in_scaleFactor = o_videoParm.n_scaleFactor;
    this.ib_isMobile = o_videoParm.b_isMobile;

    this.in_imgWWidth = 0.6 * this.in_vWidth;
    this.in_imgWHeight = 402*this.in_imgWWidth/544;

    this.in_birdHeight = Math.floor((this.in_vHeight)/10);     
    this.in_birdWidth = Math.floor((400*this.in_birdHeight)/500);

 
    this.in_SnakeHeight = 2*this.in_birdHeight/3;
    this.in_SnakeWidth = Math.floor((450*this.in_SnakeHeight)/100);
 
    this.in_ShuttleWidth = 1.5*this.in_birdWidth;
    this.in_ShuttleHeight = Math.floor((350*this.in_ShuttleWidth)/500);

    this.in_1percentWidth = this.in_vWidth/100;
    this.in_chargeBarWidth = 0.8*this.in_vWidth;
    this.in_chargeBarHeight = Math.floor((395*this.in_chargeBarWidth)/2193);

  }
  // previous allPointsInScreen




}

class PracticeObj{
  constructor(aaDesc, anRep, anStartTime){

    this.ia_desc= aaDesc;
    this.in_requiredRep=anRep;
    this.in_stage=1;
    this.in_curRep=0;
    this.in_goodSwing=0;
    this.in_goodFollowThru=0;
    this.in_goodStepBack=0;
    this.is_exerciseComment=0;
    this.in_StartTime = anStartTime;
       
  }

  clearPractice(){

    this.ia_desc=[];
    this.in_rep=0;
    this.in_stage=1;
    this.in_curRep=0;
    this.in_goodSwing=0;
    this.in_goodFollowThru=0;
    this.in_goodStepBack=0;

  }

  doRep(){
    if (this.in_requiredRep===this.in_curRep)

      return false;
    else

      return true;
  }


}

function estimateSpeechDuration(aComment){

if (aComment.length>0 && aComment.length<=5){

    return 3/ln_utterSpeed;

  }else if (aComment.length>4 && aComment.length<=20){

    return 4/ln_utterSpeed;

  }else if (aComment.length>20 && aComment.length<=30){

    return 5/ln_utterSpeed;

  }else if (aComment.length>30 && aComment.length<=40){

    return 6/ln_utterSpeed;

  }else if (aComment.length>40 && aComment.length<=50){

    return 7/ln_utterSpeed;

  }else if (aComment.length>50 ){

    return 8/ln_utterSpeed;

  }

}

function translateNumToEng(anum){

  if (anum===1)
    return "one";
  else if (anum===6)
    return "six";
  else if (anum===2)
    return "two";
  else if (anum===3)
    return "three";
  else if (anum===4)
    return "four";
  else if (anum===5)
    return "five";

  return "";

}

function validReadings(ap_l, an_vWidth, an_vHeight, ab_isMobile, an_scaleFactor){

 
  var b_allPtsValid = true;

  for (var i=0; i < 33; i++){

    var p_x = ap_l[i].x;
    var p_y = ap_l[i].y;

    if (!ab_isMobile){
      p_x = p_x/an_scaleFactor;
      p_y = p_y/an_scaleFactor;

    }

    /*

      if (p_y > an_vHeight || p_x > an_vWidth 
      ||p_y <0 || p_x  <0 ){

      b_allPtsValid = false;

      logIt(i+" False!! ln_vHeight:" +an_vHeight + " ln_vWidth:" + an_vWidth
      + " p.keypoints[i].y:" + p_y+ " p.keypoints[i].x:" + p_x + " n_outPtTime:" + n_outPtTime);
      
    }

    */

  

  }

  if ( ap_l[11].y>ap_l[28].y
    || ap_l[24].y>ap_l[26].y
    || ap_l[23].y>ap_l[25].y
    ||ap_l[12].y>ap_l[27].y
    ){

    logIt("ap_l[11].y>ap_l[28].y:" + ap_l[11].y>ap_l[28].y + " ap_l[24].y>ap_l[26].y:" +ap_l[24].y>ap_l[26].y
    + " ap_l[23].y>ap_l[25].y:"+ ap_l[23].y>ap_l[25].y + " ap_l[12].y>ap_l[27].y:" + ap_l[12].y>ap_l[27].y);
    b_allPtsValid = false;

  }
/*
|| (stats.stdev(arryLhY)>20 &&stats.stdev(arryRhY)>20) 

+ " stats.stdev(arryLhY):" + stats.stdev(arryLhY) +" stats.stdev(arryRhY):" +stats.stdev(arryRhY)

*/
  if(b_allPtsValid){
    if(n_outPtTime >0){

      n_outPtTime = 0;
    }
    

    //window.scrollTo(0,document.body.scrollHeight);
/*
if (b_tooNear){

      document.getElementById('sSnakeCount').style.display="none";

      document.getElementById('canvasContainer').scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });

            b_tooNear = false;

    }
*/
    
    

    //b_notPause=true;

  }else
  if(!b_allPtsValid && n_outPtTime>0 && timeLapsed-n_outPtTime>8){

    //instruct("Please check that your entire body is visible on screen");

    logIt("setComment for: Please check that your entire body is visible on screen");

    setComment(timeLapsed, "Please check that your entire body is visible on screen");
    

    //sSnakeCount
    //document.getElementById('sSnakeCount').style.display="block";
    //document.getElementById('sSnakeCount').innerHTML="Please check that your entire body is visible on screen.";
    //n_outPtTime = timeLapsed;
    //b_tooNear=true;
    //document.documentElement.scrollTop =0;
  }else if(!b_allPtsValid && n_outPtTime===0){
    //b_notPause=false;
    n_outPtTime = timeLapsed;
  }

  logIt("In validReadings, points are " + b_allPtsValid);
  return b_allPtsValid;

}

function getAvgX(aarry, an_index){

  var tmp_arryX=[];

  for (var t=0; t< aarry.length; t++){
   
    tmp_arryX.push(aarry[t][an_index].x);
  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.mean(tmp_arryX);

}

function getAvgY(aarry, an_index){

  var tmp_arryY=[];

  for (var t=0; t< aarry.length; t++){
   
    tmp_arryY.push(aarry[t][an_index].y);
  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryY:" +stats.mean(tmp_arryY) );

  return stats.mean(tmp_arryY);

}

function checkStdev(aarry, an_index, an_sstev){

  var tmp_arryX=[];
  var tmp_arryY=[];
  for (var t=0; t< aarry.length; t++){
    tmp_arryX.push(aarry[t][an_index].x);
    tmp_arryY.push(aarry[t][an_index].y);
  }

  /*
  logIt("length arry: " + aarry.length + " stdev tmp_arryX:" +stats.stdev(tmp_arryX)
  + " stdev tmp_arryY:" +stats.stdev(tmp_arryY) );
  */

  if (stats.stdev(tmp_arryX)< an_sstev && stats.stdev(tmp_arryX)< an_sstev){
    return true;
  }else{
    return false;
  }


}

function setUpDeviceParam(){

  var ln_vWidth=document.getElementById('video').videoWidth; 

  var ln_vHeight=document.getElementById('video').videoHeight; 
   n_scaleFactor = 1;

  var b_isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

  logIt("is it mobile:" +b_isMobile);

  logIt("video size: width:" + ln_vWidth + " height:" + ln_vHeight);
  logIt("screen size: width:" + window.innerWidth + " height:" + window.innerHeight);
      
  if (window.innerHeight > window.innerWidth ){ // portrait

    document.getElementById('canvasContainer').style.width = window.innerWidth+"px";
    document.getElementById('canvasContainer').style.height = window.innerHeight+"px";

    if(window.innerWidth < ln_vWidth){
      n_scaleFactor = window.innerWidth/ln_vWidth;
    }


  }else {

    if (window.innerHeight>=720 && window.innerWidth>=960 ){


      document.getElementById('canvasContainer').style.width ="960px";
      document.getElementById('canvasContainer').style.height = "720px";

      n_scaleFactor = 960/ln_vWidth; 
    }else if (window.innerHeight >= ln_vHeight && window.innerWidth >= ln_vWidth){
      n_scaleFactor=1;
      document.getElementById('canvasContainer').style.width =ln_vWidth+"px";
      document.getElementById('canvasContainer').style.height = ln_vHeight+"px";

    }else{

      document.getElementById('canvasContainer').style.width = window.innerWidth+"px";
      document.getElementById('canvasContainer').style.height = window.innerHeight+"px";

      if(window.innerWidth < ln_vWidth){
        n_scaleFactor = window.innerWidth/ln_vWidth;
      }
    }

    if (!b_isMobile){

      document.getElementById('canvasContainer').scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }

  }


  logIt("scale factor:" +n_scaleFactor);
  return {n_videoWidth:ln_vWidth,n_videoHeight:ln_vHeight,n_scaleFactor: n_scaleFactor, b_isMobile: b_isMobile }
      //document.getElementById('sLevel').innerHTML = "ln_vWidth:" + ln_vWidth + ", ln_vHeight:" + ln_vHeight;
  
}

function creImage(imgName,x,y,w,h){

  var imgC = document.createElement('img');
  imgC.src = imgName;
  //logIt("Creating img:" + imgName + " x:" + x + " y:" +y);
  try{
    camera.drawPic(imgC,{x:x,y:y},w,h);
  }catch(err){
    logIt(imgName +"- Error drawing:" + err.message);
  }
  

}

function PlaySound(as_obj, a_sFileName, ab_loop){

  // as_obj = new Audio(a_sFileName);
   as_obj.src = a_sFileName
   as_obj.loop = ab_loop;
   as_obj.play(); 
 }
 
function StopSound(as_obj){
   //as_obj.pause();
   //as_obj.currentTime = 0;
   as_obj.src = s_inAudible;
   as_obj.loop = false;
   as_obj.play(); 
 
 }

 function instructNow(instStr){

  b_notSpeaking = true;
  ln_promptTime = timeLapsed - ln_configRA -1;

  logIt("In InstructNow, instStr:" + instStr + " vs last spoken:" + s_lastSpoken);
  if (instStr!== s_lastSpoken){
    instruct(instStr);
  }else{
    logIt("In InstructNow, instr:" + instStr + " not spoken. Suspect repeating instruction." )
  }
  


}

function instruct(instStr){

  instructBase(instStr, ln_configRA);

}

function instructBase(ainstStr, an_waitTime){

  
  logIt("in instruct for: " + ainstStr + " with b_notSpeaking:" + b_notSpeaking );

  if (b_notSpeaking && timeLapsed-ln_promptTime > an_waitTime){
    
    b_notSpeaking = false;
    logIt("b_notSpeaking set:" + b_notSpeaking + " for " + ainstStr + " with ln_promptTime:" + ln_promptTime);

    s_lastSpoken = ainstStr;

    if (b_SI){

      var arryVoices = synth.getVoices();


      utterThis.text =ainstStr;
      //utterThis.voice = utterVoice;
      utterThis.volume = 0.6;
      utterThis.rate = ln_utterSpeed;
      utterThis.onend = function(){

        b_startState=true;
        ln_promptTime = timeLapsed;
        b_notSpeaking = true;
        logIt(b_startState +" startStated onended now b_notSpeaking set:" + b_notSpeaking + " for " + ainstStr);

      };
      ln_promptTime = timeLapsed;
      logIt("speaking: " + ainstStr);
      synth.speak(utterThis);
      s_lastSpoken = ainstStr;
      logIt("spoke: " + ainstStr);
      
    }
  
    return true;
    
  }else{    
    
      return false;

  }
}

function instructRep(ainstStr, an_waitTime){

  logIt(" with b_notSpeaking:" + b_notSpeaking +" in instruct Rep for: " + ainstStr );

  if (b_notSpeaking && timeLapsed-ln_promptTime > an_waitTime){
    b_notSpeaking = false;
    logIt("in instruct Rep set b_notSpeaking:" + b_notSpeaking);
    s_lastSpoken = ainstStr;

    if (b_SI){


      utterThat.text =ainstStr;
      //utterThis.voice = utterVoice;
      utterThat.rate = ln_utterSpeed;
      utterThat.onend = function(){

        //b_startState=true;
        ln_promptTime = timeLapsed;
        b_notSpeaking = true;
        logIt(" startStae onended Rep b_notSpeaking set:" + b_notSpeaking + " for " + ainstStr );

      };
      ln_promptTime = timeLapsed;
      logIt("Speaking: " + ainstStr);
      synth.speak(utterThat);
      logIt("spoke: " + ainstStr);
      
    }
  
    return true;
    
  }else{   
    
    return false;

  }

}

function justSetComment(aTimeLapsed, aCommentStr){

  if (b_notSpeaking){

    s_comment = aCommentStr.toLowerCase();
    n_sDuration = estimateSpeechDuration(aCommentStr);
    nt_nextComment = timeLapsed + n_sDuration;
    logIt("In just set comment. Overwritten with "+ aCommentStr + " new nt_nextComment:" + nt_nextComment);

  }else{
    s_pComment = s_pComment+" " + aCommentStr;
  }



}

function setComment(aTimeLapsed, aCommentStr){

  logIt("n_lastCommentTime: " + n_lastCommentTime + " ln_configRA:"+ ln_configRA 
  + " s_prevComment:" + s_prevComment.trim() + " aCommentStr:" + aCommentStr.trim());

  //if (aTimeLapsed > nt_nextComment){
  if (b_notSpeaking){

        // must be in to avoid overwhelming comments
    if(aTimeLapsed - n_lastCommentTime > ln_configRA || aCommentStr.trim() !==s_prevComment.trim()){ 

      logIt("aTimeLapsed - n_lastCommentTime: " + (aTimeLapsed - n_lastCommentTime) + " aCommentStr.trim() !==s_prevComment.trim():" + (aCommentStr.trim() !=s_prevComment.trim()));
      logIt("comment Set");
      s_comment = aCommentStr;
      n_sDuration = estimateSpeechDuration(aCommentStr);
  
    }else{
      logIt("No comment Set");
    }

  }else{

    logIt("not time yet. nt_nextComment:" + b_notSpeaking);
  }



}

function checkNotOnHold(an_state){

  if (an_state!==n_holdState){
    logIt("not holding: "+ an_state );
    return true;
  }else{
    logIt("holding: "+ an_state + " but check for release");
    if (timeLapsed > nt_nextComment){
      n_holdState =-99;
      logIt("released b_notSpeaking:" + b_notSpeaking);
      return true;
    }else{
      logIt("state: " + an_state + " still on hold till:" + b_notSpeaking);
      return false;
    }

  }

}

function justSay(){

  if ((s_comment.length>0 || s_pComment.length>0)&& b_notSpeaking){

    b_notSpeaking = false;
    nt_nextComment = timeLapsed + n_sDuration;
    utterThis.rate = ln_utterSpeed;
    s_comment = s_comment + " " + s_pComment;
    utterThis.text =s_comment;
    utterThis.onend = function(){

      b_notSpeaking = true;
      //n_lastCommentTime = timeLapsed;
      logIt("onended:" +  utterThis.text + " set b_notSpeaking:" + b_notSpeaking);

    };
    logIt("saying: " + s_comment + " nt_nextComment:" + nt_nextComment);

    synth.speak(utterThis);
    // s_lastSpoken = ainstStr;
  
    s_prevComment = s_comment;
    n_lastCommentTime = timeLapsed;
    s_comment = "";
    s_pComment = "";

  }else{

    logIt("say nothing");
  }

}


/// End Display and Sound Functions
/// Start Program Logic functions

/*

function getPL(p){

  var ap_l = [];
 
  if (p[12].x > p[11].x){
    ap_l.push(p[0]);
    for (var i=1; i < 33; i++){

      if (i%2===0){
        ap_l.push(p[i-1]);
        //ap_l[i] = p[i-1];

      }else{
        ap_l.push(p[i+1]);
        //ap_l[i] = p[i+1];
      }

    }

  }else{
    ap_l = p;
  }

  return ap_l;

}

*/

function scaleValue(v, an_scale){

  if (an_scale>0){

    for (var i=0; i < 33; i++){

      v[0].keypoints[i].x = v[0].keypoints[i].x/an_scale;
      v[0].keypoints[i].y = v[0].keypoints[i].y/an_scale;
  
    }

  }


  return v;
}

function getPL(p, an_scaleX, an_scaleY){

  var ap_l = [];
 
  
  p[0].x = p[0].x/an_scaleX;
  p[0].y = p[0].y/an_scaleY;
  ap_l.push(p[0]);

  if (p[12].x > p[11].x){

    for (var i=1; i < 33; i++){


      if (i%2===0){
        p[i-1].x = p[i-1].x/an_scaleX;
        p[i-1].y = p[i-1].y/an_scaleY;
        ap_l.push(p[i-1]);
        //ap_l[i] = p[i-1];

      }else{
        p[i+1].x = p[i+1].x/an_scaleX;
        p[i+1].y = p[i+1].y/an_scaleY;
        ap_l.push(p[i+1]);
        //ap_l[i] = p[i+1];
      }

    }

  }else{
 
    for (var i=1; i < 33; i++){

      p[i].x = p[i].x/an_scaleX;
      p[i].y = p[i].y/an_scaleY;
      ap_l.push(p[i]);

    }

  }

  return ap_l;

}

function getSpokenDesc(aDesc){

  var oa_desc = aDesc.split(",");
  var s_spokenDesc = "";

  var n_index=0;
  s_spokenDesc = oa_desc[0].trim();

  if (oa_desc.length>1){

    n_index++;
    while(n_index < oa_desc.length-1){

      s_spokenDesc = s_spokenDesc +", "+  oa_desc[n_index].trim();
      n_index++;

    }

    s_spokenDesc = s_spokenDesc +" and "+  oa_desc[oa_desc.length-1].trim();

  }

  return {sspokenDesc: s_spokenDesc, aDesc: oa_desc}

}

function getANGYX(pp,np){

  return Math.atan(Math.abs(pp.y-np.y)/Math.abs(pp.x-np.x)) * 180 / Math.PI;

}

function getSignedANGXY(pp,np){

  
  return Math.atan((pp.x-np.x)/(pp.y-np.y)) * 180 / Math.PI;

}

function getANGXY(hp,lp){

  return Math.atan(Math.abs(lp.x-hp.x)/Math.abs(lp.y-hp.y)) * 180 / Math.PI;

}

function getDistance(p1, p2){

  return Math.sqrt(Math.pow((p1.x-p2.x),2) + Math.pow((p1.y-p2.y),2));

}

function toDegrees (angle) {
  return angle * (180 / Math.PI);
}

function getArea(p1, p2, p3){

  var d12, d13,d23, p, area;
  
  d12 = getDistance(p1, p2);
  d13 = getDistance(p1, p3);
  d23 = getDistance(p2, p3);

  p = (d12,d13,d23)/2;

  area = Math.sqrt(p * (p-d12)* (p-d13)* (p-d23));
  logIt("Found area: " + area);

  return area;


}

function getANGby3Points(s, eb, w){

  var Dseb, Debw,Dsw;
  
  Dseb = getDistance(s, eb);
  Debw = getDistance(eb, w);
  Dsw = getDistance(s, w);

  //document.getElementById('angle').innerHTML = "Dseb2: " + (Dseb*Dseb) + " Debw2: " + (Debw*Debw) + " Dsw2: " + (Dsw*Dsw);

  if (Dsw+Debw+Dseb ===0){

    return 370;
  }else{


    return toDegrees(Math.acos((Dseb*Dseb + Debw*Debw-Dsw*Dsw)/(2*Dseb*Debw)));

    //return (Dsw/(Dsw+Debw+Dseb)) * 180;
  }

}

function getObtuseANG(ANG){

  if (ANG<0){

    return 180+ ANG;
  }else{

    return ANG;
  }

}

function checkValBetw(val, upperVal, lowerVal, msg){

 if (val < upperVal && val > lowerVal){

    return 1;
  }else{

    logIt('Failed for ' + msg + ' with values: ' + val + ' upperVal:' + upperVal + ' lowerVal:' + lowerVal );

    return 0;

  }
 
}

function generateRandomIntegerInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}



function logIt(als_log){

  ls_log += timeLapsed+": "+als_log + "\\n";
  //ls_log="";
}






function convertToMinSec(aTimeDuration){

  if (Math.floor(aTimeDuration/60)>0){
    return Math.floor(aTimeDuration/60) + "min " + Math.ceil(aTimeDuration%60)+"sec";
  }else{
    return Math.ceil(aTimeDuration%60)+"sec";
  }
  

}
/*
function calTimeDuration (aCurrentWave, aCurrDuration,ab_all){

  var ltimeDuration = aCurrDuration; 

  if (aCurrentWave>1 && ab_all){

    for (var w=1; w<aCurrentWave; w++){

      ltimeDuration = ltimeDuration + n_initGTime*(Math.pow(1+n_diff,w-1));
      
    }

  }

  
  return Math.round(ltimeDuration);


}

*/

function ValidateEmail(inputText)
{
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if(inputText.match(mailformat))
  {

   return true;
  }
  else
  {

    return false;
  }
}

/// End Program Logic

/// Start video capturing/ AI

async function createDetector() {
  /*

switch (STATE.model) {
    case posedetection.SupportedModels.PoseNet:
      return posedetection.createDetector(STATE.model, {
        quantBytes: 4,
        architecture: 'MobileNetV1',
        outputStride: 16,
        inputResolution: {width: 500, height: 500},
        multiplier: 0.75
      });
    case posedetection.SupportedModels.MediapipeBlazepose:
      return posedetection.createDetector(STATE.model, {quantBytes: 4});
    case posedetection.SupportedModels.MoveNet:
      const modelType = STATE.modelConfig.type == 'lightning' ?
          posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING :
          posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
      return posedetection.createDetector(STATE.model, {modelType});
  }

  */

  
  STATE.model = posedetection.SupportedModels.MediapipeBlazepose;
  return posedetection.createDetector(STATE.model, {quantBytes: 2});
  

  /*
  return await posenet.load({
    architecture: 'MobileNetV1',
    outputStride: 16,
    inputResolution: { width: 640, height: 480 },
    multiplier: 0.75
  });
  */
  
}

function waitForCoach(ab_speak){

  
  sSnakeCount.style.display="block";
  sSnakeCount.innerHTML="Await Coach instructions.";
  logIt("Set text wait coach");
  if (ab_speak){
    //instructRep("Await Coach instructions.",2*ln_configRA);
    //document.documentElement.scrollTop =0;
    logIt("setComment for: Await Coach instructions.");

    setComment(timeLapsed, "Await Coach instructions.");
  }
  
  
}

let getPose = async () => { 

  if (typeof(detector) === "undefined"){

    return null;

  }else{

    try{
      const poses = detector.estimatePoses(
        camera.video,
        {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});
    
        return poses;
    }catch(err){
      logIt("End Err: " + err);
      return null;
    }

  }

 };

 async function renderResult() {

  timeLapsed = (Date.now() - startTime)/1000;
  
  if (n_server < 2)
    document.getElementById("Timer").innerText = timeLapsed;
  //timer.innerHTML = timeLapsed

  if (b_timer){
    try{
      
      if (typeof(camera) !== "undefined"){

        try{
          if (!b_showLine){
            camera.drawCtx();
            //camera.drawResults(value);
          }
          
        }catch(cErr){

          logIt("Err drawing ctx:" + cErr.message);

        }
        
      }
           
  
      if (typeof(o_animate.in_vHeight) === "undefined"||o_animate.in_vHeight===0 ){
    


        o_animate.initDeviceParam();
        o_animate.in_nextEventTime = timeLapsed;

        if (typeof(o_animate.in_vHeight) !== "undefined"){
          setStatus(s_myID,"Starting Camera","")
        }

        document.getElementById('canvasContainer').scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
    
      }else{
    
        if (n_state===1){ // all states -ve do not need display
    
          logIt("state 1 next event time:" + o_animate.in_nextEventTime + " with mode:"+ n_mode);

          if (o_animate.in_nextEventTime - timeLapsed <3){

            logIt("setComment for: Ready");

            setComment(timeLapsed, "Ready");

          }else if (o_animate.in_nextEventTime - timeLapsed <5){
            if (b_waveReadyPrompt){
              //instructNow("Ready");

              PlaySound(ad_snake,"ShuttleHitSnake.mp3",false);   
              b_waveReadyPrompt = false;

              //ad_background.volume = 0.2;
              PlaySound(ad_background,"Background.mp3",true);
            }
            o_animate.showWaveBanner();
          }
          

          if (timeLapsed > o_animate.in_nextEventTime){
    
            o_animate.in_nextEventTime = timeLapsed ;
    
            if (n_mode===2){       
              //o_animate.waveCleared();
              //logIt("Init birds");
              o_animate.initBirdParam();
            }
    

            o_animate.in_waveStartTime = timeLapsed;
            o_animate.initGameParam(n_initEatTime,n_initSpawnTime,n_initGTime);
            
            setStatus(s_myID,"Game Started","");
            //instructNow("Smash!!");

            logIt("setComment for: Smash!!");

            setComment(timeLapsed, "Smash!!");

            n_state=2;
    
          }
    
        }else if(n_state===2){
    
          
          n_state = o_animate.animateGame(timeLapsed);
          if (n_state===4){

            PlaySound(ad_countDown,"BellCelebrateHorn.mp3",false);
            o_hrm.clearShoot();
            o_animate.in_nextEventTime = timeLapsed+0.7;
            o_animate.targetAllSnakes();
          }else if (n_state===-8){
    
            o_animate.in_nextEventTime = timeLapsed +0.5;
    
          }
          
          
    
        }else if(n_state===4){
          if(timeLapsed > o_animate.in_nextEventTime){
            
            o_animate.showElements(timeLapsed);
      
          }else{
            
            if (parseInt(s_myID)%2===1){
              sendRealTimeUpdates(s_myID,Math.round(o_animate.in_gLevelTime)
              ,o_animate.in_superCharge,o_animate.in_perfectCount,o_animate.in_snakeCount,o_animate.in_gLevel
              ,o_animate.in_shuttleCount,o_animate.in_goodSwingCount,o_animate.in_followThruCount
              ,o_animate.in_stepBackCount,s_waitingStatus,s_waitingRemarks);
            }
            o_animate.waveCleared();

            o_animate.saveWave(false,timeLapsed);
  
            if(n_mode===2){

              //instructNow("Wave "+o_animate.in_gLevel+" Cleared. Await Coach instructions.");

              logIt("setComment for: Wave "+o_animate.in_gLevel+" Cleared.");

              setComment(timeLapsed, "Wave "+o_animate.in_gLevel+" Cleared.");

              n_state=-1;
              waitForCoach(false);
              logIt("scrollTop wave cleared");
              document.documentElement.scrollTop =0;

            }else{

              //instructNow("Wave "+o_animate.in_gLevel+" Cleared.");

              logIt("setComment for: Wave "+o_animate.in_gLevel+" Cleared.");

              setComment(timeLapsed, "Wave "+o_animate.in_gLevel+" Cleared.");

              if (o_animate.in_gLevel===n_maxLevel && n_mode===1){
                n_state =-9; // show report;
              }else{
                o_animate.in_nextEventTime = timeLapsed + n_breakTime;
                n_state=-1;
              }

            }
    
          }
    
        }else if(n_state===-8){
          //
          if (o_animate.in_nextEventTime>timeLapsed){
            o_animate.animateGame(timeLapsed);
          }else{

            o_animate.waveCleared();
            o_animate.saveWave(true,timeLapsed);
            if (n_mode===1){
              b_timer=false;
              endGame();
            }else if (n_mode===2){
  
              waitForCoach(true);
              logIt("scrollTop state -8  mode 2");
              document.documentElement.scrollTop =0;
              n_state=-1;
  
            }

          } 
          
 
        }else if(n_state===-9){
          //
          if (timeLapsed > o_animate.in_nextEventTime ){
            b_timer=false;
            //o_animate.saveWave();
            endGame();
          }else{
            o_animate.animateGame(timeLapsed);
          }
          
        }else if(n_state===-2){

          if (b_validReading){

            //instructRep("Rest Pose",1.2*ln_configRA);

            logIt("setComment for: Rest Pose.");

            setComment(timeLapsed, "Rest Pose.");

            creImage("RestPose.png",(o_animate.in_vWidth/2)- 145
            ,o_animate.in_birdHeight, 290, 80);

          }


        }else if(n_state===-3){

          logIt("showing the options");

          if(n_practiseIndex > 0){

            creImage(arryPractiseIcon[n_practiseIndex-1],(o_animate.in_vWidth/2)+ 10 + (n_pIconHeight/2)
            ,0, n_pIconHeight/2,n_pIconHeight/2);

          }

          creImage(arryPractiseIcon[n_practiseIndex],(o_animate.in_vWidth/2)- (n_pIconHeight/2)
          ,0, n_pIconHeight, n_pIconHeight);

          if (n_practiseIndex+1 < arryPractiseIcon.length){

            creImage(arryPractiseIcon[n_practiseIndex+1],(o_animate.in_vWidth/2)- 10 -n_pIconHeight
            ,0, n_pIconHeight/2,n_pIconHeight/2);

          }

        }
    
        getPose().then((value) => {
          
            if(document.getElementById('loading').style.display === ""){

             
              document.getElementById('loading').style.display = "none";
              document.getElementById('canvasContainer').scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });  

              logIt("hide loading gif center canvas");

            } 
            

            
            n_lastPoseUpdate = timeLapsed;
            //if (value && value.length ){
            if (value && value.length && validReadings(value[0].keypoints
            ,o_animate.in_vWidth, o_animate.in_vHeight, o_animate.ib_isMobile, o_animate.in_scaleFactor)){

              b_validReading =true;
              var p = value[0];
              var bx=0,by=0,bwidth=0,bheight=0;
              data = data + "\\n" 
              + timeLapsed+","+ p.keypoints[0].x+ "," + p.keypoints[0].y+ ","
              + p.keypoints[2].x+ "," + p.keypoints[2].y+ ","+ p.keypoints[5].x+ "," + p.keypoints[5].y+ ","
            
              + p.keypoints[7].x+ "," + p.keypoints[7].y +","+ p.keypoints[8].x+ "," + p.keypoints[8].y+ ","
              + p.keypoints[11].x+ "," + p.keypoints[11].y +","+ p.keypoints[12].x+ "," + p.keypoints[12].y+ ","
              + p.keypoints[13].x+ "," + p.keypoints[13].y +","+ p.keypoints[14].x+ "," + p.keypoints[14].y+ ","
              + p.keypoints[15].x+ "," + p.keypoints[15].y +","+ p.keypoints[16].x+ "," + p.keypoints[16].y+ ","
              + p.keypoints[17].x+ "," + p.keypoints[17].y +","+ p.keypoints[18].x+ "," + p.keypoints[18].y+ ","
              + p.keypoints[19].x+ "," + p.keypoints[19].y +","+ p.keypoints[20].x+ "," + p.keypoints[20].y+ ","
              + p.keypoints[21].x+ "," + p.keypoints[21].y +","+ p.keypoints[22].x+ "," + p.keypoints[22].y+ ","
              + p.keypoints[23].x+ "," + p.keypoints[23].y +","+ p.keypoints[24].x+ "," + p.keypoints[24].y+ ","
              + p.keypoints[25].x+ "," + p.keypoints[25].y +","+ p.keypoints[26].x+ "," + p.keypoints[26].y+ ","
              + p.keypoints[27].x+ "," + p.keypoints[27].y +","+ p.keypoints[28].x+ "," + p.keypoints[28].y+ ","
              + p.keypoints[29].x+ "," + p.keypoints[29].y +","+ p.keypoints[30].x+ "," + p.keypoints[30].y+ ","
              + p.keypoints[31].x+ "," + p.keypoints[31].y +","+ p.keypoints[32].x+ "," + p.keypoints[32].y+ ","
              +bx+","+ by+","+ bwidth+","+ bheight+","
              + timeLapsed;  
            
            
            var sv;

            try{
              sv = scaleValue(value,n_scaleFactor);
            }catch(svErr){
              logIt("Err with scaleValue: " + svErr.message);
            }

            logIt("scaled Value.");
            if (b_showLine){
              camera.drawCtx();
              camera.drawResults(sv);
              //camera.drawResults(value);
            }
    
            var p_l;
    
            try{
              p_l = getPL(value[0].keypoints,n_scaleFactor,n_scaleFactor);
            }catch (plErr){

              logIt("Err with PL: " + plErr.message);
            }

            if (p_l[2].y >0 && n_pIconHeight-10 > p_l[2].y && n_state===-3){
              if (p_l[2].y <60){
                logIt("force set icon height to 60")
                n_pIconHeight = 60;
              }else{
                logIt("set icon height to: " + p_l[2].y)
                n_pIconHeight = p_l[2].y;
              }
              
            }
            
            logIt("gotten PL.");
            if (n_state===-2){

              //instructBase("Rest Pose",ln_configRA*1.5);
              if (n_startRestPoseTime<0){
                n_startRestPoseTime = timeLapsed;
              }

              var n_rpStatus = o_hrm.detectRestPose(timeLapsed,p_l);

              //n_rpStatus=1;
              if(n_rpStatus===1){
              //if(true){
                
                b_showLine=false;
                
                logIt("Rest pose detected");
                n_waveTime = timeLapsed + ln_configRA;
                
                if (n_mode%2===0){ // for all coach led sessions wait for coaches
                  n_lastWBLRequest = -6;
                  waitForCoach(true);
                  logIt("scrollTop coach led RP");
                  document.documentElement.scrollTop =0;

                  n_state =-1;

                }else if (n_mode===1){
                  // challenge mode do not need to initBirdParam again. Coach led initBird after every wave
                  o_animate.initBirdParam();
                  n_state =-1;
                } else if(n_mode===-1){

                  logIt("back to n_state:" + n_state);
                  n_state=-3;
                }
                               
               
    
              }else if(n_mode%2===0){

                var n_rp = Math.round(timeLapsed - n_startRestPoseTime);

                if (n_rp>10){
                  b_showLine=true;
                  //document.getElementById('practiseText').innerText = "set showline:" + b_showLine;
                }

                switch(n_rpStatus) {
                  case -1:
                    setStatus(s_myID,"Rest Pose","Stabilizing");
                    break;
                  case -2:
                    setStatus(s_myID,"Rest Pose since " +n_rp +"secs" ,"Reading Unstable");
                    break;

                  case -3:
                    setStatus(s_myID,"Rest Pose since " +n_rp +"secs" ,"Wrong Pose");
                    break;
                    
                  default:
                    break;
                }

                //setStatus(s_myID,)
                //sendUpdate
              }
    
            }else if (n_state===-1){ // waveBreak
    
              n_prevMode = n_mode;
              if (n_mode%2===0){//coach led
                 
                getWBL(s_pc,s_myID,s_waitingStatus,s_waitingRemarks)
                .then(//waveData => o_waveInfo= waveData
                  waveData=>{
                    if (waveData.status==="go"){

                      if (waveData.gLevel<100){
                        o_animate.in_gLevel = waveData.gLevel;
                        o_animate.in_nextEventTime = timeLapsed +waveData.timeToNextWave;
                        sLevel.innerHTML = "Wave " + o_animate.in_gLevel;

                        initGameUI();
                        sSnakeCount.style.display="none";
                       // instruct("Get Ready");
                        n_mode=2;
                        if (n_prevMode> -99 &&n_prevMode!==n_mode){
                          PlaySound(ad_background,"Background.mp3",true);
                        }
                        b_waveReadyPrompt = true;
                        n_state=1;          

                      }else{

                        endGame();

                      }

                    }else if(waveData.status==="practise"){

                      n_mode=0;


                      var o_tmpDesc = getSpokenDesc(waveData.practiseDesc);

                      b_startState=false;
                      //var instr = "Let's practise "+ translateNumToEng(waveData.nReps) +" "+ o_tmpDesc.sspokenDesc + ". Into Exercise Pose";
                      //instructNow("Let us practise "+ o_tmpDesc.sspokenDesc + ". Into Exercise Pose");

                      logIt("justSetComment for: Let's try "+ o_tmpDesc.sspokenDesc + ". Into Exercise Pose");

                      justSetComment(timeLapsed, "Let's try "+ o_tmpDesc.sspokenDesc);

                      o_practise = new PracticeObj(o_tmpDesc.aDesc, waveData.nReps,timeLapsed);
                      n_state=-4;
                      n_holdState=-4;
                      logIt("Holding state:" + n_holdState);

                      initPractiseUI();
                      sSnakeCount.style.display="none";
                                            
                      //document.getElementById('').innerText = "Let's practise " + o_tmpDesc.sspokenDesc;
                      document.getElementById('practiseText').innerText = "Let's try " + o_tmpDesc.sspokenDesc;                      

                      o_practise = new PracticeObj(o_tmpDesc.aDesc, waveData.nReps,timeLapsed);
                      n_state=-4;
                      n_holdState=-4;
                      logIt("Holding state:" + n_holdState);
                     
                    }else{

                      waitForCoach(true);

                    }

                    if (window.innerHeight > window.innerWidth){

                      logIt("scrollTop into practise portrait");
                      document.documentElement.scrollTop =0;

                    }else{

                      logIt("scroll to canvas");
                      document.getElementById('canvasContainer').scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center'
                      });  

                    }
    
                  }
                
                  );
    
              }else if (n_mode===1){ // challenge
    
                if (timeLapsed > o_animate.in_nextEventTime){
    
                  o_animate.in_gLevel++;
                  sLevel.innerHTML = "Wave " + o_animate.in_gLevel;
                  o_animate.in_nextEventTime = timeLapsed +n_bannerDecoTime; 
                  
                  b_waveReadyPrompt = true;
                  n_state=1;
                 
    
                }
    
              }
    
            }else if(n_state===2){

              o_hrm.inExercise(timeLapsed,p_l)

            }else if (n_state===-4 && checkNotOnHold(-4)){


              logIt("in -4 with ad_background.src:" + ad_background.src);
              if (ad_background.src.includes("Background.mp3")){

                logIt("stopping sound");
                StopSound(ad_background);

              }

              // cross cout

              if (n_mode===-1){

                if (p_l[16].y < p_l[14].y && p_l[15].y < p_l[13].y
                  && p_l[20].x > p_l[19].x && p_l[13].x > p_l[14].y
                  ){

                    justSetComment(timeLapsed, "End Exercise");
                    n_state=-3;

                }

              }

              if (o_practise.doRep()){

                logIt("doing reps: " + o_practise.in_curRep + " with stage:" + o_practise.in_stage);

                if (o_practise.in_stage===-1 ){

                  logIt("setComment for: Rest Pose.");

                  setComment(timeLapsed, "Rest Pose.");

                  var n_rpStatus = o_hrm.detectRestPose(timeLapsed,p_l);

                  if (checkNotOnHold(-11)){

                    if (n_rpStatus===1){

                      justSetComment(timeLapsed, "Exercise Pose.");
                      n_holdState=11;
                      o_practise.in_stage=1;
  
                    }

                  }


                }else if (o_practise.in_stage===1 && checkNotOnHold(11)){
                 
                  o_hrm.detectExPose(timeLapsed, p_l,true)
                  logIt("check ex pose: " + o_hrm.ib_showShuttle);
                  //o_hrm.ib_showShuttle = true;
                  if (o_hrm.ib_showShuttle){
  
                    if (o_practise.ia_desc[0]!=="Exercise Pose"){

                      //instructNow("Good Pose. Go");

                      logIt("setComment for: Good Pose. Go");

                      //setComment(timeLapsed, "Good Pose. Go",4);
                      justSetComment(timeLapsed, "Good Pose. Go.");
                      n_holdState=12;
                      o_practise.in_stage=2;
                    }else{

                      o_practise.in_curRep++;

                      //instructNow("Good Pose.");

                      logIt("setComment for: Good Pose.");

                      justSetComment(timeLapsed, "Good.");
                      //setComment(timeLapsed, "Good. Rest Pose.",4);
                      n_holdState=-11;
                      o_practise.in_stage=-1;
 
                      
                      o_hrm.clearShoot();
                      //n_state=-1;
                      logIt("In getPose, Exercise Pose, clear shoot. set state:" + n_state);

                    }
                      
                  }
  
                }else if (o_practise.in_stage===2 && checkNotOnHold(21)){
                   
                  o_hrm.detectExCompleted(timeLapsed, p_l, o_practise.ia_desc);
                  logIt("In getPose, check ex completed: " + o_hrm.ib_shoot);
                  //o_hrm.ib_shoot=true;
                  if (o_hrm.ib_shoot){

                    o_practise.is_exerciseComment ="";                

                    if (o_practise.ia_desc.includes("Swing")){
        
                      if (o_hrm.ib_goodSwing){
                        o_practise.in_goodSwing++;
                        o_practise.is_exerciseComment = "Swing is good. ";
                      }else{
                        o_practise.is_exerciseComment = "Straighten your arms and elbow near ear when swing";
                      }
          
                    }
          
                    if (o_practise.ia_desc.includes("Follow Through")){
          
                      if (o_hrm.ib_followThru){
                        o_practise.in_goodFollowThru++;
                        o_practise.is_exerciseComment = o_practise.is_exerciseComment +  "Follow through is good.";
                      }else{
          
                        if (!o_hrm.ib_footForward)
                          o_practise.is_exerciseComment = o_practise.is_exerciseComment +  "Racket feet step forward. ";
          
                        if (!o_hrm.ib_crossedBody)
                          o_practise.is_exerciseComment = o_practise.is_exerciseComment +  "Racket arm cross body. ";
                        
                        o_practise.is_exerciseComment = o_practise.is_exerciseComment +  "For good follow through. ";
                      }
          
                    }
          
                    if (o_practise.ia_desc.includes("Step Back")){
          
                      if (o_hrm.ib_stepBack){
                        o_practise.in_goodStepBack++; 
                        o_practise.is_exerciseComment =o_practise.is_exerciseComment + "Step back is good.";
                      }else{
                        o_practise.is_exerciseComment =o_practise.is_exerciseComment + "Take a step back.";
                      }
          
                    }

                    logIt("In getpose, comment for this ex:" +o_practise.is_exerciseComment )         
//                    instructNow(o_practise.is_exerciseComment);

                    logIt("setComment for:" +o_practise.is_exerciseComment);

                    justSetComment(timeLapsed, o_practise.is_exerciseComment);
                    n_holdState=11;
                    o_practise.in_stage=1;
                    //instructNow("Good Swing");
                   
                    o_practise.in_curRep++;

                    logIt("Done rep:" + o_practise.in_curRep + " with cGoodSwing:" + o_practise.in_goodSwing 
                    +" cFT:" +  o_practise.in_goodFollowThru + " cSB:"+ o_practise.in_goodStepBack);
                   
                    o_hrm.clearShoot();
                  }
    
                }

              }else{ // done all reps

                logIt("Done all reps");
                if (n_mode===0){

                  s_waitingStatus = "Practice Completed";
                  s_waitingRemarks = "";
                  sendRealTimePracticeUpdates(s_myID,(timeLapsed - o_practise.in_StartTime ),o_practise.in_curRep
                    ,o_practise.in_goodSwing,o_practise.in_goodFollowThru,o_practise.in_goodStepBack,s_waitingStatus,s_waitingRemarks);
  
                  o_practise.clearPractice();
                  //send to coach
                  logIt("done all Reps. Wait for coach");
                  //waitForCoach(true);
                  n_state=-1;

                }else if(n_mode===-1){

                  logIt("return to n_state:" + n_state);
                  n_state=-3;

                }
 

              } 

            }else if (n_state===-3){

              logIt("In n_state:" + n_state +" rhandY:" +p_l[20].y);

              //if ((p_l[20].y < n_pIconHeight && p_l[20].x > (o_animate.in_vWidth/2 -n_pIconHeight/2) && p_l[20].x < (o_animate.in_vWidth/2+n_pIconHeight/2))
              //|| (p_l[19].y < n_pIconHeight && p_l[19].x > (o_animate.in_vWidth/2 -n_pIconHeight/2) && p_l[19].x < (o_animate.in_vWidth/2+n_pIconHeight/2))
              if(p_l[20].y < p_l[2].y
              ){

                if (b_prevChoice){

                  logIt("b_prevChoice:" + b_prevChoice);

                  if (arryPractiseText[n_practiseIndex]==="Start Game"){

                    logIt("selected :" + arryPractiseText[n_practiseIndex] + " prevChoice:" + b_prevChoice);                   
                    justSetComment(timeLapsed,"Starting");
                    initGameUI();
                    o_animate.initBirdParam();
                    n_mode=1;
                    n_state=-1;
                   
   
                  }else{
  
                    justSetComment(timeLapsed,"Let's try " + arryPractiseText[n_practiseIndex]);
                    document.getElementById('practiseText').innerText = "Let's try " +arryPractiseText[n_practiseIndex]; 
                     
                    o_practise = new PracticeObj([arryPractiseText[n_practiseIndex]], 3,timeLapsed);
                    n_state=-4;
                    n_holdState=-4;
                    logIt("Holding state:" + n_holdState);
  
                  }
                  b_prevChoice=false;

                }else{
                  logIt("First Pass set choice:" + b_prevChoice);
                  b_prevChoice=true;
                }



        

              }else if(p_l[16].y < p_l[14].y){ // check for swipe movement

                //swipe left seq
                if (n_pSelectionState===0 && p_l[16].x <p_l[12].x){
                  n_pSelectionState=1;
                }else if(n_pSelectionState===1 && p_l[16].x > p_l[12].x && p_l[16].x < p_l[7].x ){
                  n_pSelectionState=2;
                }else if(n_pSelectionState===2 && p_l[16].x > p_l[7].x ){

                  managePractiseIndex(1);
                  n_pSelectionState=0;

                } else if (n_pSelectionState===0 && p_l[16].x > p_l[7].x){
                  n_pSelectionState=3;

                }else if (n_pSelectionState===3 && p_l[16].x > p_l[12].x && p_l[16].x < p_l[7].x ){

                  n_pSelectionState=2;

                }else if (n_pSelectionState===2 && p_l[16].x <p_l[12].x){

                  managePractiseIndex(-1);
                  n_pSelectionState=0;
                  
                }


              }else{

                
                b_prevChoice=false;
                logIt("reset choice as " + b_prevChoice);

                if (arryPractiseText[n_practiseIndex] ==="Start Game"){

                  setComment(timeLapsed,arryPractiseText[n_practiseIndex] + "?");

                }else{

                  setComment(timeLapsed,"Practise " + arryPractiseText[n_practiseIndex] + "?");
                }
                

              }

              if(p_l[16].y > p_l[14].y){
                n_pSelectionState=0;

              }

            }
    
            
          }
              
        }); // get pose

        if (n_lastPoseUpdate>0){

 //         if(true){
          if(timeLapsed - n_lastPoseUpdate > 3){

            try{

              logIt("stopping camera");
              camera.stopVideo();
            }catch(camEx){

              logIt("Err when stopping video:" + camEx.message)
            }


            if (n_mode%2===0){
              sendRealTimeUpdates(s_myID,0
              ,0,0,0,0
              ,0,0,0
              ,0,"Err Occurred ","AI not returning pose readings");
            }

            b_timer = false;
            document.getElementById('Exercise').style.display = "none";
            document.getElementById('Err').style.display = "block";

            ReportIssue();
            

          }

        }
    
      }

    }catch(ex){

      logIt("Stopping video Err:" + ex )

    }
  }
  
  justSay();

} // RenderResult

async function setUpCapturing(){

    await tf.setBackend(STATE.backend);

    camera = await Camera.setupCamera(STATE.camera);
  
    await tf.ready();
  
    detector = await createDetector();

    //instructNow("Starting prediction");
    renderPrediction();
}

async function renderPrediction() {
 
if (b_timer){
 
  try{

    await renderResult();
    rafId = requestAnimationFrame(renderPrediction);

   }catch(err1){
    logIt("Error under renderPrediction:"  + err1);
    //instruct("Err in renderPrediction");
   }
      
  
}


};

/// End video capturing/AI

/// Start Control logic



function backToPlayBack(){

  b_timer=false;
  //camera.stopVideo();
  document.getElementById('Playback').style.display = "block";
  document.getElementById('Exercise').style.display="none";


}

function endGame(){

  camera.stopVideo();
  //sendData("CkNear2");

  
  var rv1=0,rv2=0,rv0=0,pr=0;

  /*

  n_allShuttleCount= n_allShuttleCount+n_shuttleCount; 
  n_allSnakeCount=n_allSnakeCount+n_snakeCount;
  n_allGoodSwingCount=n_allGoodSwingCount+ n_goodSwingCount;
  n_allFollowThruCount=n_allFollowThruCount+ n_followThruCount;
  n_allStepBackCount=n_allStepBackCount+ n_stepBackCount;
  n_allPerfectCount = n_allPerfectCount + n_perfectCount;
  n_allSc = n_allSc + n_sc;

  */


  var arryResults=[], arrySorted=[], arryReview=[];

  arryResults.push(new LabelValue("Good Swing(%)",o_animate.in_goodSwingCountAll,"ShuttleS.png"));
  arryResults.push(new LabelValue("Good Follow Through(%)",o_animate.in_followThruCountAll,"ShuttleFT.png"));
  arryResults.push(new LabelValue("Good Step Back(%)",o_animate.in_stepBackCountAll,"ShuttleSB.png"));

  //s_nick="",s_email="",s_mobile=""
  //var timeDuration = Math.round(timeLapsed);

  var timeDuration = Math.round(o_animate.in_gameTimeAll);

//rgd
  var tempWinner;
  if (arryResults[2].rvalue > arryResults[1].rvalue){
    tempWinner = arryResults[2];
    arryReview.push(arryResults[1]);
  }else{
    tempWinner = arryResults[1];
    arryReview.push(arryResults[2]);

  }

  if (tempWinner.rvalue > arryResults[0].rvalue){
    arryReview.push(arryResults[0]);
  }else{
    arryReview.push(tempWinner);
    tempWinner = arryResults[0];
    
  }

  arrySorted.push(tempWinner);

  if (arryReview[0].rvalue > arryReview[1].rvalue){
    arrySorted.push(arryReview[0]);
    arrySorted.push(arryReview[1]);
  }else{
    arrySorted.push(arryReview[1]);
    arrySorted.push(arryReview[0]);

  }

  if (o_animate.in_shuttleCountAll>0){
    
    rv0 = Math.ceil(100*arrySorted[0].rvalue/o_animate.in_shuttleCountAll);
    rv1 = Math.ceil(100*arrySorted[1].rvalue/o_animate.in_shuttleCountAll);
    rv2 = Math.ceil(100*arrySorted[2].rvalue/o_animate.in_shuttleCountAll);
    pr = Math.ceil(100*o_animate.in_perfectCountAll/o_animate.in_shuttleCountAll);

  }

  if (n_mode===2){

    document.getElementById('ryr').style.display="none";


  }else if(s_pc ==="obagame4u"){
/*

    sendResult(s_nick,s_mobile,s_email,timeDuration,n_allSc,pr
      ,n_allSnakeCount,n_gLevel-1,n_allShuttleCount,window.innerWidth,window.innerHeight);

*/


    sendResult(s_nick,s_mobile,s_email,timeDuration,o_animate.in_superChargeAll,pr
        ,o_animate.in_snakeCountAll,o_animate.in_wavesCleared,o_animate.in_shuttleCountAll,window.innerWidth,window.innerHeight);

  }


  if (pr>50){
    document.getElementById('cfa').innerHTML = "You are NATURAL! But we can make you better!!"
  }else{

    document.getElementById('cfa').innerHTML = "Like to unleash your true potential? Let us help you!!"
  }

  
  document.getElementById('Result').style.display = "block";
  

  document.getElementById('rsu').innerHTML =o_animate.in_shuttleCountAll;
  document.getElementById('rsk').innerHTML =o_animate.in_snakeCountAll;

  document.getElementById('rwc').innerHTML =o_animate.in_wavesCleared;

  document.getElementById('rph').innerHTML = pr;
  document.getElementById('rsc').innerHTML =o_animate.in_superChargeAll;


  document.getElementById('rgd').innerHTML = convertToMinSec(timeDuration);
  

  document.getElementById('rt1').innerHTML = arrySorted[0].label;
  document.getElementById('rv1').innerHTML = rv0;

  document.getElementById('s1').src = arrySorted[0].imageFile;

  document.getElementById('rt2').innerHTML = arrySorted[1].label;
  document.getElementById('rv2').innerHTML = rv1;
  document.getElementById('s2').src = arrySorted[1].imageFile;

  document.getElementById('rt3').innerHTML = arrySorted[2].label;
  document.getElementById('rv3').innerHTML = rv2;
  document.getElementById('s3').src = arrySorted[2].imageFile;

  document.documentElement.scrollTop =0;
  timeLapsed=0;

  document.getElementById('Exercise').style.display="none"; 

 
}

function setupProgramParam(){

 sLevel.innerHTML="";
 
 b_timer=true;
 
setUpCapturing();

}

function initAudio(){

  
  

  ad_countDown = new Audio();
  ad_countDown.src = s_inAudible;
  ad_countDown.volume = 0.2;
  ad_countDown.play();

  ad_chickBitten = new Audio();
  ad_chickBitten.src = s_inAudible;
  ad_chickBitten.volume = 0.2;
  ad_chickBitten.play();

  ad_shuttle = new Audio();
  ad_shuttle.src = s_inAudible;
  ad_shuttle.volume = 0.2;
  ad_shuttle.play();

 ad_lastChick = new Audio();
 ad_lastChick.src = s_inAudible;
 ad_lastChick.volume = 0.2;
 ad_lastChick.play();

 ad_background= new Audio();
 ad_background.src = s_inAudible;
 ad_background.volume = 0.2;
 ad_background.play();

 ad_charging = new Audio();
 ad_charging.src = s_inAudible;
 ad_charging.volume = 0.2;
 ad_charging.play();

 ad_snake=new Audio();
 ad_snake.src = s_inAudible;
 ad_snake.volume = 0.2;
 ad_snake.play();

 ad_ulti=new Audio();
 ad_ulti.src = s_inAudible;
 ad_ulti.volume = 0.2;
 ad_ulti.play();

}

function initPractiseUI(){

  document.getElementById('Playback').style.display="none";
  document.getElementById('Exercise').style.display="block";
  document.getElementById('practiseText').style.display="block";
  document.getElementById('condition').style.display="none";
 
  sSnakeCount.style.display="block";

  sSnakeCount.innerText = "";

}

function initPractise(){


}

function initGameUI(){

  document.getElementById('Playback').style.display="none";
  document.getElementById('Exercise').style.display="block";

  document.getElementById('practiseText').style.display="none";
  document.getElementById('condition').style.display="block";


 document.getElementById('trVideo').src = "";

}

function exerciseSelection(num){

  //document.getElementById('menu').style.display="none";
  document.getElementById('Landing').style.display="none";
  document.getElementById('Playback').style.display="block";

  document.documentElement.scrollTop =0;

}

/// End Control Logic

// Start External Interaction

function ReportIssue(){

  b_timer=false;

  if (n_server > 0){
    var nVer = navigator.appVersion;

    ls_log = "Agent: " +nVer+"\\n" 
    + "Device: " + document.getElementById('DeviceDesc').value + "\\n"
    + "Problem: "+document.getElementById('problemDesc').value + "\\n" + ls_log;

    sendData("IssueReport");

  }



}

async function setStatus (aMyID, aStatus, aRemarks){

  if (timeLapsed - n_lastWBLRequest > n_pollingFreq && b_notSending && (n_mode%2===0)){
    n_lastWBLRequest = timeLapsed;
    const axios = require('axios');
    //var prevWBL = n_wbl;
   
    var bodyFormData = new FormData();

    bodyFormData.append('aMyID',aMyID);
    bodyFormData.append('aStatus',aStatus);
    bodyFormData.append('aRemarks',aRemarks);

    var s_url="";

    if (n_server>0){
      s_url = s_urlPrefix+"StudentRecs/Details?handler=SetStatus";
    }else{
      s_url = "https://localhost:44374/StudentRecs/Details?handler=SetStatus";
    }

    try{

      let o_waveData = await axios({
        method: "post",
        url: s_url,
       
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })

      if (o_waveData.status===200){

        return {status:"ok"};

      }
    
      return {status:"failed"};

    }catch(err){
      return {status:"err"};
    }
    

  }else{

    return {status:"NA"};
  }


}

async function sendRealTimePracticeUpdates (as_myID,aDuration,an_attempt
  , an_goodSwing, an_followThru, an_stepBack,aStatus,aRemarks){


    b_notSending = false;

    const axios = require('axios');   
    var bodyFormData = new FormData();

    bodyFormData.append('aRecID', as_myID);
    bodyFormData.append('aDuration', Math.round(aDuration));

    bodyFormData.append('aAttempts', an_attempt);

    bodyFormData.append('aGoodSwing', an_goodSwing);
    bodyFormData.append('aFollowThru', an_followThru);
    bodyFormData.append('aStepBack', an_stepBack);
    bodyFormData.append('aStatus', aStatus);
    bodyFormData.append('aRemarks', aRemarks);

    var s_url="";

    if (n_server>0){
      s_url = s_urlPrefix+"PracticeRecs/Edit?handler=UpdatePracRec";
    }else{
      s_url = "https://localhost:44374/PracticeRecs/Edit?handler=UpdatePracRec";
    }

    axios({
      method: "post",
      url: s_url,
  
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {

        var b_status = response.data.status;
        b_notSending = true;
  
      })
      .catch(function (response) {
        
        var b_status = response.data.status;
        b_notSending = true;
  
      });
  
      n_lastWBLRequest = timeLapsed;
}

async function sendRealTimeUpdates (as_myID,aDuration,an_sc,an_perfectCount,an_snakeCount
  ,an_gLevel,an_shuttleCount, an_goodSwing, an_followThru, an_stepBack,aStatus,aRemarks){


      b_notSending = false;

      logIt("Sending Real time updates");
      const axios = require('axios');

      var n_percentPH=0,n_goodSwing=0, n_followThru=0, n_stepBack=0;
      if(an_shuttleCount>0){
        n_percentPH = Math.ceil(100*an_perfectCount/an_shuttleCount);  
        n_goodSwing = Math.ceil(100*an_goodSwing/an_shuttleCount); 
        n_followThru = Math.ceil(100*an_followThru/an_shuttleCount); 
        n_stepBack = Math.ceil(100*an_stepBack/an_shuttleCount);     
      }
     
      var bodyFormData = new FormData();
  
      bodyFormData.append('aRecID', as_myID);
      bodyFormData.append('aDuration', Math.round(aDuration));
      bodyFormData.append('aSCC', an_sc);
      bodyFormData.append('aPerfectHit', n_percentPH);
      bodyFormData.append('aSnakeKilled', an_snakeCount);
      bodyFormData.append('aWaveCleared', an_gLevel--);
      bodyFormData.append('aShuttleUsed', an_shuttleCount);
  
      bodyFormData.append('aGoodSwing', n_goodSwing);
      bodyFormData.append('aFollowThru', n_followThru);
      bodyFormData.append('aStepBack', n_stepBack);
      bodyFormData.append('aStatus', aStatus);
      bodyFormData.append('aRemarks', aRemarks);
  
      var s_url="";

      if (n_server>0){
        s_url = s_urlPrefix+"StudentRecs/Edit?handler=UpdateRec";
      }else{
        s_url = "https://localhost:44374/StudentRecs/Edit?handler=UpdateRec";
      }

      axios({
        method: "post",
        url: s_url,
   
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
  
          var b_status = response.data.status;
          b_notSending = true;
    
        })
        .catch(function (response) {
          
          var b_status = response.data.status;
          b_notSending = true;
    
        });
    
        n_lastWBLRequest = timeLapsed;



}

async function checkCreateSessionRec(aPc, aNick){

  const axios = require('axios');

  var bodyFormData = new FormData();

  bodyFormData.append('aSessionID', aPc);
  bodyFormData.append('aNick', aNick);


  var s_url="";

  if (n_server>0){
    s_url = s_urlPrefix+"StudentRecs/Create?handler=CheckCreateSession";
  }else{
    s_url = "https://localhost:44374/StudentRecs/Create?handler=CheckCreateSession";
  }

  return new Promise(function (resolve,reject){

    axios({
      method: "post",
      url: s_url,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
  
        if (response.data.status==="Ok"){
          s_myID = response.data.msg;
          
          n_mode=2;
          //b_rejoin = response.data.rejoin;
          //n_maxLevel = parseInt(response.data.max);
/*
          if (b_rejoin){

            n_gLevel = response.data.curLevel;
            //n_gLevel++

          }
          */
          resolve(true);
        }else{
          s_myID ="";
         
          resolve(false);
  
        }
  
      })
      .catch(function (response) {
  
        logIt("SendCreate Err:" + response);
  
        reject(false);
  
      });

  });


}

async function getWBL (aSessionID,aMyID,aStatus, aRemarks){

  if (timeLapsed - n_lastWBLRequest > n_pollingFreq){
    n_lastWBLRequest = timeLapsed;
    const axios = require('axios');
    //var prevWBL = n_wbl;
   
    var bodyFormData = new FormData();

    bodyFormData.append('aSessionID',aSessionID);
    bodyFormData.append('aMyID',aMyID);
    
    if (aStatus.length>0){
      bodyFormData.append('aStatus',aStatus);
    }else{
      bodyFormData.append('aStatus',"Ready");
    }
      
    bodyFormData.append('aRemarks',aRemarks);
    //bodyFormData.append('aMode',aMode);

    var s_url="";

    if (n_server>0){
      s_url = s_urlPrefix+"CoachRecs/Details?handler=GetWBL2";
    }else{
      s_url = "https://localhost:44374/CoachRecs/Details?handler=GetWBL2";
    }

    try{

      let o_waveData = await axios({
        method: "post",
        url: s_url,
       
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })

      if (o_waveData.status===200){

        var currDate = Date.now();

        logIt("In getWBL, return currDate:" + currDate + " n_lastExeDate:" + n_lastExeDate + " o_waveData.data.nwt:" + Date.parse(o_waveData.data.nwt));
        if (Date.parse(o_waveData.data.nwt)>currDate && Date.parse(o_waveData.data.nwt)>n_lastExeDate){

          if (o_waveData.data.status==="ok"){

            //n_mode=2;
            var n_timeDiff = Date.parse(o_waveData.data.nwt)-currDate;
            return {status:"go",timeToNextWave:n_timeDiff/1000,gLevel:parseInt(o_waveData.data.cl)};
  
  
          }else { // implied is practise
            
            //n_mode=0;
            logIt("Practising for " + o_waveData.data.desc + " with Reps:" + o_waveData.data.rep);
            n_lastExeDate = Date.parse(o_waveData.data.nwt);
            return {status:"practise",practiseDesc:o_waveData.data.desc,nReps:parseInt(o_waveData.data.rep)};
            
          }


        }else{

          return {status:"wait"};

        }

      }
    
      return {status:"failed"};

    }catch(err){
      return {status:"err"};
    }
    
  }else{

    return {status:"coolDown"};
  }


}

function sendData(aFileName){

  b_timer = false;
    
  const axios = require('axios');

  var currentdate = new Date();
  var newFileName = aFileName
  +"_"+ currentdate.getDate() + "_"
  + (currentdate.getMonth()+1)  + "_" 
  + "t"  
  + currentdate.getHours() + "_"  
  + currentdate.getMinutes() + "_" 
  + currentdate.getSeconds();
  var bodyFormData = new FormData();

  bodyFormData.append('afileName', newFileName);
  bodyFormData.append('csvData', data);
  bodyFormData.append('csvLog', ls_log);

  var s_url="";

  if (n_server>0){
    s_url = "https://csm2.achieversprofile.com/c/d?handler=Log";
  }else{
    s_url = "https://csm2.achieversprofile.com/c/d?handler=Log";
  }

  axios({
    method: "post",
    url: s_url,
   
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      //handle success
      //console.log(response); w:480, H:640
      b_timer = false;  

    })
    .catch(function (response) {
      //handle error

      b_timer = false;
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}

function sendFeedback (aRecID,aFB){

  b_timer = false;
    
  const axios = require('axios');

  var bodyFormData = new FormData();

  bodyFormData.append('aID', aRecID);
  bodyFormData.append('aFB', aFB);
 
  var s_url="";

  if (n_server>0){
    s_url = "https://csm2.achieversprofile.com/BGameRecs/Create?handler=UpdateFB";
  }else{
    s_url = "https://localhost:44349/BGameRecs/Create?handler=UpdateFB";
  }

  axios({
    method: "post",
    url: s_url,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {

    var check = response.data.status;

    })
    .catch(function (response) {
      //handle error

      b_timer = false;
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}

function sendResult (aNick,aMobile,aEmail,aGameDuration,aNumSuperCharge,aPerfect
  ,aNumSnakeKill,aWaveCleared,aShuttleUsed,aScreenX,aScreenY){

  b_timer = false;
    
  const axios = require('axios');

  var bodyFormData = new FormData();

  bodyFormData.append('aNick', aNick);
  bodyFormData.append('aMobile', aMobile);
  bodyFormData.append('aEmail', aEmail);
  bodyFormData.append('aGameDuration', aGameDuration);
  bodyFormData.append('aNumSuperCharge', aNumSuperCharge);
  bodyFormData.append('aPerfect', aPerfect);
  bodyFormData.append('aNumSnakeKill', aNumSnakeKill);
  bodyFormData.append('aWaveCleared', aWaveCleared);
  bodyFormData.append('aShuttleUsed', aShuttleUsed);
  bodyFormData.append('aScreenX', aScreenX);
  bodyFormData.append('aScreenY', aScreenY);

  var s_url="";

  if (n_server>0){
    s_url = "https://csm2.achieversprofile.com/BGameRecs/Create?handler=AddRank";
  }else{
    s_url = "https://localhost:44349/BGameRecs/Create?handler=AddRank";
  }
  
  axios({
    method: "post",
    url: s_url,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {

      document.getElementById('rYourRank').innerHTML = response.data.yourRank;
      s_recID = response.data.recID

    })
    .catch(function (response) {
      //handle error

      //b_timer = false;
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}

function managePractiseIndex(aChange){

  n_practiseIndex += aChange;

  if (n_practiseIndex < 0)
    n_practiseIndex=0;
  else if (n_practiseIndex>=arryPractiseIcon.length){
    n_practiseIndex = arryPractiseIcon.length-1;
  }
  
}

function getRank(){

  b_timer = false;
    
  const axios = require('axios');

  var s_url="";

  if (n_server>0){
    s_url = "https://csm2.achieversprofile.com/BGameRecs/Index?handler=GetRank";
  }else{
    s_url = "https://localhost:44349/BGameRecs/Index?handler=GetRank";
  }

  axios({
    method: "post",
    url: s_url,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {

      /*

            <td class="tg-itoz">5</td>
            <td class="tg-itoz"><span id=nick5></span>></td>
            <td class="tg-itoz"><span id=wc5></span>></td>
            <td class="tg-itoz"><span id=sk5></span>></td>
            <td class="tg-itoz"><span id=ph5></span>></td>
            <td class="tg-itoz"><span id=sc5></span>></td>
            <td class="tg-itoz"><span id=gd5></span>></td>

      */
      

      var ls_ranking = response;

      var arryRecs = response.data.sorted.split(";");

      var s_tableHead = "<table class='tg'><tr><th class='tg-my09'>Rank</th><th class='tg-my09'>Nick</th><th class='tg-my09'>Waves Cleared</th><th class='tg-my09'>Snakes Killed</th><th class='tg-my09'>Perfect Hit%</th><th class='tg-my09'>Super Charge Unleashed</th><th class='tg-my09'>Game Duration</th> </tr>";

      var s_tableBottom = "</table>";
      var s_tableContent="";
      for (var r=0; r < arryRecs.length; r++){

        s_tableContent = s_tableContent + " <tr>";

        var arryfields = arryRecs[r].split(",");

        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+arryfields[0]+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+arryfields[1]+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+arryfields[2]+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+arryfields[3]+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+arryfields[4]+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+arryfields[5]+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+convertToMinSec(parseInt(arryfields[6]))+"</td>";
/*
        document.getElementById('nick' + (r+1)).innerHTML= arryfields[1];
        document.getElementById('wc' + (r+1)).innerHTML= arryfields[2];
        document.getElementById('sk' + (r+1)).innerHTML= arryfields[3];
        document.getElementById('ph' + (r+1)).innerHTML= arryfields[4];
        document.getElementById('sc' + (r+1)).innerHTML= arryfields[5];
        document.getElementById('gd' + (r+1)).innerHTML= convertToMinSec(parseInt(arryfields[6]));
        */

        s_tableContent = s_tableContent + " </tr>";

        

      }
      document.getElementById('tblTopsSavior').innerHTML = s_tableHead+ s_tableContent + s_tableBottom;

      //document.getElementById('fileName').style.visibility = "hidden";
      

    })
    .catch(function (response) {
      //handle error
 
      b_timer = false;
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}

///End External interaction

async function app() {
  data="";
  arryLen=6;
  n_mode=1;
  n_state=-2; // default is -1
  b_validReading = false;
  n_lastPoseUpdate =-1;
  b_showLine=false;
  s_waitingRemarks = "";
  s_waitingStatus = "";
  n_practiseIndex =0;

  n_lastCommentTime = -1*ln_configRA;

  logIt("Program initiated");
  
  var sw= Math.floor(0.8* window.innerWidth);
  var exh = Math.floor(315*sw/560);
// 1.1.3: send device status to admin
// 1.1.4: fix sound & black screen.
//1.1.51: practise mode and issue report features.
//1.1.6: self directed practise mode
  document.getElementById('ver').innerHTML="ver 1.1.6";

  document.getElementById('help').addEventListener("click", function(){

    document.getElementById('help').style.display="none";
    document.getElementById('helpForm').style.display="block";

  });

  document.getElementById('sendProblem').addEventListener("click", function(){

    ReportIssue();

    document.getElementById("Landing").style.display="none";
    document.getElementById("Playback").style.display="none";
    document.getElementById("Exercise").style.display="none";
    document.getElementById("Result").style.display="none";
    document.getElementById("Err").style.display="none";
    //document.getElementById("helpForm").style.display="none";
    document.getElementById("ErrSent").style.display="block"; 


  });

  document.getElementById('promoVideo').style.width = sw+"px";
  document.getElementById('promoVideo').style.height = exh+"px";
 
  document.getElementById('trVideo').style.width = sw+"px";
  document.getElementById('trVideo').style.height = exh+"px";

  document.getElementById('startP').addEventListener("click", function(){

    if (n_mode===2){
      n_mode=0;
    }else{
      n_mode=-1; // self directed
      n_state=-2;
      arryPractiseIcon.push("EP.png");
      arryPractiseIcon.push("SW.png");
      arryPractiseIcon.push("FT.png");
      arryPractiseIcon.push("GM.png");

      arryPractiseText.push("Exercise Pose");
      arryPractiseText.push("Swing");
      arryPractiseText.push("Follow Through");
      arryPractiseText.push("Start Game");

      arryPractiseText.push("Step Back");
    }

    logIt("Starting Practise");
    utterThis.text = "Let's Practise.";
    utterThis.volume = 0.9;
    synth.speak(utterThis);

    initPractiseUI();


    b_timer=true;

    setUpCapturing();

  });

  document.getElementById('startEx').addEventListener("click", function(){

   
    //setStatus(s_myID,"Getting Started", "");
    utterThis.text = "Starting";
    utterThis.volume = 0.9;
    synth.speak(utterThis);

    logIt("Starting Exercise");

    initGameUI();
  
//   utterThat.text = "Starting";

//   utterThat.volume=0.5;
//   synth.speak(utterThat);

    if (o_animate.ib_isMobile)
    {
      logIt("in startEx is mobile. Scoll to top");
    //  document.documentElement.scrollTop =0;
    }

    setupProgramParam();

  });


  getRank();

  //checkCreateSessionRec(s_pc,"tester");

  var userInfo = "";
  userInfo = document.cookie;

  if (userInfo.length>0){

    var arryKeyValue = userInfo.split(";");
    if (arryKeyValue.length>0){

      for(var c=0; c<arryKeyValue.length; c++ ){

        var arrySplit = arryKeyValue[c].split("=");

        if (arrySplit[0].trim()==="BSemail"){
          document.getElementById('email').value = arrySplit[1].trim();
        }

        if (arrySplit[0].trim()==="BSmobile"){
          document.getElementById('mobile').value = arrySplit[1].trim();
        }

        if (arrySplit[0].trim()==="BSnick"){
          document.getElementById('nick').value = arrySplit[1].trim();
        }

        if (arrySplit[0].trim()==="pc"){
          document.getElementById('passcode').value = arrySplit[1].trim();
        }

      }

    }

  }

  startTime = Date.now();

  //net = await cocossd.load();
  
  b_timer = true;

  if (n_server===2){

    n_initGTime=45;
    s_urlPrefix="https://obabe.achieversprofile.com/"
    //n_gLevelTime=45;

  }else{
    
    n_initGTime=20;
    //n_gLevelTime=20;

    var btnShowBird = document.createElement("button");
    
    //btnShowBird.innerHTML = "Show";
    btnShowBird.innerHTML = "Left";
    btnShowBird.addEventListener("click", function() {

      managePractiseIndex(1);
      //n_state=1;
    
    });

    var btnShoot = document.createElement("button");
    //btnShoot.innerHTML = "Shoot";
    btnShoot.innerHTML = "Right";
  
    btnShoot.addEventListener("click", function() {
      //o_hrm.ib_shoot= true;
      //o_hrm.ia_shuttles = ["ShuttleFT.png","ShuttleSB.png","ShuttleS.png"];
      managePractiseIndex(-1);
  
    });

    var btnPose = document.createElement("button");
    //btnShoot.innerHTML = "Shoot";
    btnPose.innerHTML = "Pose";
  
    btnPose.addEventListener("click", function() {

      o_hrm.ib_showShuttle = true;
  
    });


    var btnSend = document.createElement("button");
    btnSend.innerHTML = "Send";
  
    btnSend.addEventListener("click", function() {

      sendData("ckTutorial");
    
    });

    var lblTimer = document.createElement("Label");
    lblTimer.setAttribute("id","Timer");



    document.getElementById('main').appendChild(btnShowBird);
    document.getElementById('main').appendChild(btnShoot);
    document.getElementById('main').appendChild(btnPose);
    document.getElementById('main').appendChild(btnSend);
    document.getElementById('main').appendChild(lblTimer);

  }

  sLevel =  document.getElementById('sLevel');
  sSnakeCount =  document.getElementById('sSnakeCount');
  //timer  = document.getElementById('timer');
  // for migration only, should be calledd in Start Ex button



  //setupProgramParam();

  o_animate = new AnimateObj(n_diff); 
  o_hrm = new HrmObj(n_mode);
  


  data = "DT,nX,nY"
        +",leX,leY,reX,reY,learX,learY,rearX,rearY"
        +",lsX,lsY,rsX,rsY,lebX,lebY,rebX,rebY"
        +",lwX,lwY,rwX,rwY,lpX,lpY,rpX,rpY"
        +",liX,liY,riX,riY,ltX,ltY,rtX,rtY"
        +",lhX,lhY,rhX,rhY,lkX,lkY,rkX,rkY"
        +",laX,laY,raX,raY,lheX,lheY,rheX,rheY"
        +",lfiX,lfiY,rfiX,rfiY"
        +",bx,by,bwidth,bheight,DT";

  document.getElementById('subFB').addEventListener("click", function(){

  //  s_fb = document.getElementById('fb').value;

    sendFeedback(s_recID, document.getElementById('fb').value);
    document.getElementById('fb').value = "";
    document.getElementById('fbForm').style.display="none";
    document.getElementById('fbResponse').style.display="block";

  });

  document.getElementById('submit').addEventListener("click", async function(){

    logIt("Submitting");
    document.getElementById('promoVideo').src = "";
    s_email = document.getElementById('email').value;
    s_nick = document.getElementById('nick').value.trim();
    s_mobile = document.getElementById('mobile').value;

    s_pc = document.getElementById('passcode').value;

    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    var s_BLpc = "obagame4u";

    initAudio();




//    exerciseSelection(1);
    var b_checkCreateSessionStatus = await checkCreateSessionRec(s_pc,s_nick);
if ( s_nick.length >0 && b_checkCreateSessionStatus){

 
  document.cookie = "BSnick="+s_nick;
  document.cookie = "pc="+s_pc;
  
  //instructNow("Welcome");
  exerciseSelection(1);

// ValidateEmail(s_email) && s_mobile.length >7 &&

}else if (s_nick.length >0 && !format.test(s_nick) 
&& (s_pc===s_BLpc ||s_pc=== "ttt") && document.getElementById('cbx_tc').checked){

      document.cookie = "BSemail="+s_email;
      document.cookie = "BSnick="+s_nick;
      document.cookie = "BSmobile="+s_mobile;
      document.cookie = "pc="+s_pc;
      
      //instructNow("Welcome");


      //initAudio();
  
      exerciseSelection(1);

    }else{



      /*

      (!ValidateEmail(s_email)){
        promptTxt = "Please enter a valid email";
      }else if (!(s_mobile.length >7)){
        promptTxt = "Please enter a valid mobile number";
      }else if 
      */

      var promptTxt = "";

      if (!(s_nick.length >0)){
        promptTxt = "Please enter a nick name";
      }else if(format.test(s_nick)){
        promptTxt = "Nickname can only have letters and numbers";
      }else if((s_pc!==s_BLpc&&s_pc!== "ttt")){
        promptTxt = "Please enter valid passcode";
      }
      else if(!document.getElementById('cbx_tc').checked){
        promptTxt = "Please agree to our Terms and Conditions by ticking the box";
      }

      alert(promptTxt);
    }

  });


document.getElementById('tc').addEventListener("click", function(){

  var x = document.getElementById("tcText");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
});

document.addEventListener("visibilitychange", event => {
  if (document.visibilityState !== "visible") {

   if (document.getElementById('Exercise').style.display==="block"){


      b_timer=false;


      document.getElementById('practiseText').style.display="block";
      document.getElementById('practiseText').innerHTML="Refresh browser to try again.";
      
      document.getElementById('condition').style.display="none";

      o_animate.waveCleared();
      o_animate.saveWave(true,timeLapsed);

      n_lastWBLRequest = timeLapsed-n_pollingFreq -2;
      setStatus(s_myID,"Play Ended","Exited")

      endGame();

  }

  }
});

window.addEventListener('beforeunload', function (e) {
  o_animate.waveCleared();
  o_animate.saveWave(true,timeLapsed);

  n_lastWBLRequest = timeLapsed-n_pollingFreq -2;
  setStatus(s_myID,"Play Ended","Exited")
});



/*
document.getElementById('send').addEventListener("click", function(){

  sendData("ckIphonePad");
});
*/

  //synth = window.speechSynthesis;
 

};

app();